

/**
 * Recibe un numero y lo transforma a formato moneda  
 * @param numero numero entero o decimal ej: 12345.55
 * @param decimales numero de decimales deseados; ej: 1,2,3,4,5, etc... 0 para dejarlo sin decimales
 * @return resultado ej: $12,346
 */
function FormatoMoneda(numero, decimales) {
  if (!numero){numero = 0;}
  if (!Number(numero)){numero = 0;}
  numero = FormatoDecimal(numero, decimales);
  return Number(numero).toLocaleString("en-US",{style: "currency", currency: "USD", minimumFractionDigits: decimales});
}



/**
 * Recibe un numero y ajusta a los decimales deseados  
 * @param numero numero entero o decimal ej: 12345.55
 * @param decimales numero de decimales deseados; ej: 1,2,3,4,5, etc... 0 para dejarlo sin decimales
 * @return resultado ej: 12345.6
 */
function FormatoDecimal(numero, decimales) {
  if (!numero){numero = 0;}
  if (!Number(numero)){numero = 0;}
  return Number(numero).toFixed(decimales);
}




/**
 * Recibe un numero y lo ajusta a base 10; ej: 12345.55 a multiplo 100 retorna como resultado 12300  
 * @param numero numero entero o decimal ej: 12345.55
 * @param multiplo multiplo base en la que se desea procesar; ej: 10, 100, 1000, 10000, etc
 * @return resultado ej: 12300
 */
function EnteroMultiploDiez(numero, multiplo) {
  if (!numero){numero = 0;}
  if (!Number(numero)){numero = 0;}
  return (Math.round(Number(numero) / Number(multiplo)) * Number(multiplo))
}


/**
 * Recibe un numero que representa los segundos, y congela el sistema  
 * @param segundos numero entero mayor a 1
 */
async function Congelar(segundos) {
  await new Promise(resolve => setTimeout(resolve, segundos * 1000));
}


export { FormatoMoneda, FormatoDecimal, EnteroMultiploDiez, Congelar};


