import React, { useEffect, useState } from 'react';

import { Head } from '../../../utils/Head';
import { Excel_to_JSON } from '../../../utils/ProcesarArchivos';
import { Congelar } from '../../../utils/Metodos';
import { Inp_buscar, Inp_btn_constructor } from '../../../utils/inputs';

import { DataTables } from '../../../components/DataTables/DataTables';
import { ImgEspera } from '../../../components/ImgEspera/ImgEspera';
import { ModalBasico } from '../../../components/Modal/Modal';
import { Referencias } from '../../../components/Referencias/Referencias';

import { CUPS } from '../Salud_consulta_cups_descr/CUPS';

import { useIdioma } from '../../../context/IdiomaProvider';




function ConsultaCUPSR2077_2021(props) {

  const [imgEspera, setImgEspera] = useState(false);

  const [datos, setDatos] = useState([]);
  
  const [datosfiltro, setDatosfiltro] = useState([]);

  const [msjModal, setMsjModal] = useState([]);
  
  const { tagsDataTables } = useIdioma();

  

  useEffect(() => {
    const ExcelToJSON = async () => {

      setImgEspera(true);
      
      const json = await Excel_to_JSON(props.urlFile1, 0);

      const depurado = json.map((dato)=>{

        if (dato){
          if (dato['COD_SERVICIO'] && dato['NOMBRE']){
            
            return {
              "cod": dato['COD_SERVICIO'], 
              "nombreserv": dato['NOMBRE'],
            }
          }
          else {
            return {
            "cod": "", 
            "nombreserv": "",
            }
          }
        }


      });
      
      setDatos(depurado);
      setImgEspera(false);
    };

    ExcelToJSON();
  }, []);




  
  const filtrar = async (valor) => {

    setImgEspera(true);

    const Reg = new RegExp(valor, 'gi');

    const filtro = datos.filter((dato, indice)=>{

      if (
        (dato['cod'] && String(dato['cod']).match(Reg)) ||
        (dato['nombreserv'] && String(dato['nombreserv']).match(Reg))
      ) {
        return dato;
      }

    });



    if(filtro.length === 0){
      let msj = [];
      msj.push('_SIN_COINCIDENCIAS_ENCONTRADAS');
      setMsjModal(msj);
    }

    setDatosfiltro(filtro);
    await Congelar(filtro.length/3000);
    setImgEspera(false);
  };





  const sendForm = (event) => {

    event.preventDefault();

    let objForm = document.querySelector(".form-buscar");

    objForm.classList.add('was-validated');
    
    if (!objForm.reportValidity()){
      return;
    }

    let objInput = document.querySelector('.Inp_buscar');

    filtrar(objInput.value);
  }; 




  const titulos = [
    'Código CUPS', 
    'Descripción Servicio', 
  ];





  
  return (
    <>
      <Head title={props.titulo} description={props.tituloDescr} />
      <section className='caja3-block'>
        <h3>{props.titulo}</h3>
        <p>[{props.grupo}]</p>
        <p>{props.tituloDescr}</p>
      </section>

      {msjModal.length > 0 && <ModalBasico titulo={'_INFO'} msjModal={msjModal} setMsjModal={setMsjModal} color_caja={'bg-warning'} color_letra={'text-white'} /> }

      <ImgEspera ver={imgEspera} />

      <section className='caja2-block'>
        <form className='form-buscar' >

        <Inp_buscar att_required={true} sect_msj_validation={true} att_otros={{minLength: 3}} att_comentarios={'_CONSULTA_CUPS_R2077_2021_EJEMPLO'} />

        <Inp_btn_constructor 
        clss_btn={'btn btn-ico'}
        att_name={'Inp_btn_buscar'} 
        att_value={'_BTN_BUSCAR'} 
        att_title={'_BTN_BUSCAR'} 
        btn_modelo={3}
        att_style={'btn-light'}
        svg_ico={'searchsvg'}
        svg_color={'svg-color-oscuro-forced'}
        btn_redondo={true}


        onClick={(event) => sendForm(event)} 
        />

        </form>
      </section>


      <section className='container-DataTables'>
      {
        datosfiltro.length > 0 &&
        <DataTables titulos={titulos} objDatos={datosfiltro} clasesTable={'tblDivipola table table-striped table-hover'} tagsDataTables={tagsDataTables} />
      }
      </section>

      <CUPS />

      <Referencias cita='Ministerio De Salud y Protección Social. (09 de diciembre de 2021). Por la cual se establece la Clasificación Única de Procedimientos en Salud - CUPS. [Resolución 2077 de 2021].'/>

    </>
  );
}

export { ConsultaCUPSR2077_2021 };
