import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useIdioma } from '../../context/IdiomaProvider';

import { useTheme } from '../../context/ThemeProvider';

import { LogoHome } from '../../components/LogoHome/LogoHome';



function MenuNavegacion() {

  const { setLenguaje, menuNav } = useIdioma();
  const { theme, setTema } = useTheme();
  

  const [grupos, setGrupos] = useState([]);



  //reconstruir lista de grupos segun idioma
  useEffect(() => {

    if(menuNav){
      let tmpGrupo = [];
      for (const dato in menuNav) {
        if(menuNav[dato].grupo){

          if ( !(tmpGrupo.find((datoRev) => datoRev.grupo == menuNav[dato].grupo)) ){
            let nombreIco = menuNav[dato].grupo.replace(' ', '');
            tmpGrupo.push({grupo: menuNav[dato].grupo, icono: nombreIco});
          }

        }
      }
      setGrupos(tmpGrupo);
    }

  }, [menuNav] );




  //aplicar tema de colores
  useEffect(() => {
    document.querySelector("body").className = "";
    document.querySelector("body").classList.add(theme.style);
  }, [theme] );



  if (!menuNav){ return; }



  const lang = (dato) => {
    setLenguaje(dato);
    ocultoAll();
  }; 


  const tema = (dato) => {
    setTema(dato);
    ocultoAll();
  };




//////////////////////////////
////acciones del menu ppal////
//////////////////////////////



const n2Oculto = () => {
  document.querySelector('.menu-ppal-n2').classList.add('obj-oculto');
};

const n2langOculto = () => {
  document.querySelector('.menu-ppal-n2-lang').classList.add('obj-oculto');
};

const n2themeOculto = () => {
  document.querySelector('.menu-ppal-n2-theme').classList.add('obj-oculto');
};

const n3Oculto = () => {
  const lista = document.querySelectorAll('.menu-ppal-n3');
  lista.forEach((elemento) => {
    elemento.classList.add('obj-oculto');
  });
};


const ocultoAll = () => {
  n2Oculto();
  n3Oculto();
  n2langOculto();
  n2themeOculto();
}


//-----------------------//


const verMenu2 = () => {
  n3Oculto();
  n2langOculto();
  n2themeOculto();
  document.querySelector('.menu-ppal-n2').classList.toggle('obj-oculto');
};

const verMenu2lang = () => {
  n2Oculto();
  n3Oculto();
  n2themeOculto();
  document.querySelector('.menu-ppal-n2-lang').classList.toggle('obj-oculto');
};



const verMenu2theme = () => {
  n2Oculto();
  n3Oculto();
  n2langOculto();
  document.querySelector('.menu-ppal-n2-theme').classList.toggle('obj-oculto');
};



const verMenu3 = (opcMenu) => {
  n2Oculto();
  n2langOculto();
  n2themeOculto();
  document.querySelector(`.menu-ppal-n3.${opcMenu}`).classList.remove('obj-oculto');
};


const returnToMenu2 = () => {
  n3Oculto();
  n2langOculto();
  n2themeOculto();
  document.querySelector('.menu-ppal-n2').classList.remove('obj-oculto');
};



  return (
    <>
      <section className="menu-ppal">
        <nav className="menu-ppal-n1">
          <ul>
            <li><a className="menu-n1-ico m-opc" onClick={() => verMenu2()}><span className="menusvg"></span><p>[menu]</p></a></li>
            <Link to={'/'} onClick={() => ocultoAll()}><li><span className="menu-n1-ico"><span className="home2svg"></span><p>[inicio]</p></span></li></Link>
          </ul>
          <ul>
            <li><a className="menu-n1-ico" onClick={() => verMenu2theme()}><span className="palettesvg"></span><p>[theme]</p></a></li>
            <li><a className="menu-n1-ico" onClick={() => verMenu2lang()}><span className="translatesvg"></span><p>[lang]</p></a></li>
          </ul>
        </nav>

        
        

        <section className="menu-ppal-n2 obj-oculto">
            <ul>
              {
                grupos.map((dato, indice) =>{
                  return (
                    <li key={indice}><a className="menu-n2-ico" onClick={() => verMenu3(dato.grupo)}><span className={`${dato.grupo}svg svg-color-oscuro`}></span><p>{dato.grupo}</p><span className="double_arrowsvg svg-color-oscuro"></span></a></li>
                  );
                })

              }
            </ul>
        </section>



        {
          grupos.map((datog, indiceg) =>{
          return (
            <section key={indiceg} className={`menu-ppal-n3 ${datog.grupo} obj-oculto`}>
            <a className="menu-n3-return" onClick={() => returnToMenu2()}><span className="arrow_backsvg svg-color-oscuro"></span><p>{datog.grupo}</p></a>
            <ul>
              {
                menuNav.map((datolist, indicelist) => {
                  if (datolist.grupo == datog.grupo){
                    return (
                      <li key={indicelist}>
                        <Link to={datolist.path_to} onClick={() => ocultoAll()}><p>{datolist.titulo}</p></Link>
                      </li>
                    );
                  } else {
                    return;
                  }
                })
              }
            </ul>
            </section>
          );
          })
        }



        {/*menu theme*/}
        <section className="menu-ppal-n2-theme obj-oculto">
          <ul>
            <li><section className="menu-n2-ico" onClick={() => tema('claro')} ><span className="sunsvg"></span></section></li>
            <li><section className="menu-n2-ico" onClick={() => tema('oscuro')} ><span className="batmansvg"></span></section></li>
          </ul>
        </section>

        {/*menu lang*/}
        <section className="menu-ppal-n2-lang obj-oculto">
          <ul>
            <li><section className="menu-n2-ico" onClick={() => lang('en')} ><span className="lang_engsvg"></span></section></li>
            <li><section className="menu-n2-ico" onClick={() => lang('es')} ><span className="lang_essvg"></span></section></li>
          </ul>
        </section>


      </section>

      <LogoHome />

    </>
  );
}



export { MenuNavegacion };
