import React, { createContext, useState, useEffect, useContext } from "react";

import { LocalGet, LocalSet } from '../utils/Sesiones';


const ThemeContext = createContext();

const setLocalSession = async (nombre, dato) => {
  await LocalSet(nombre, dato);
};



function ThemeProvider ({ children }){

  const [theme, setTheme] = useState([]);



  //establecer tema
  const setTema = (dato) => {

    switch (dato) {
      case 'claro':
        setTheme({tema: 'claro', style: 'global-color'});
        setLocalSession('theme', 'claro');
        break;
      case 'oscuro':
        setTheme({tema: 'oscuro', style: 'global-color-dark'});
        setLocalSession('theme', 'oscuro');
        break;
      default:
        setTheme({tema: 'claro', style: 'global-color'});
        setLocalSession('theme', 'claro');
        break;
    }

  };


  //default
  useEffect(() => {

    const validarLocalSession = async () => {
      const them = await LocalGet('theme');
      if (!them){
        setTema('claro');
      } else {
        setTema(them);
      }

    };

    validarLocalSession();

    //setTema('claro');
  }, []);


  
  return (
    <ThemeContext.Provider value={{
      theme: theme,
      setTema: setTema,
    }} >
      { children }
    </ThemeContext.Provider>
  );
}



function useTheme (){
  const objTheme = useContext(ThemeContext);
  return objTheme;
}



export { ThemeProvider, useTheme };
