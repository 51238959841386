import React, { useState } from 'react';

import { useIdioma } from '../context/IdiomaProvider';



function Word(datos){
  const { vlrWord } = useIdioma();
  return vlrWord(datos);
}





//inputs//
//inputs//
//inputs//
//inputs//

/**
* @callback inputTextCallback
* @param att_value valor de input
* @param att_maxlength numero de caracteres máximo del valor del input
* @param att_required true o false --requerido como obligatorio
* @param att_disabled true o false --input habilitado (true) o deshabilitado (false) para diligenciamiento
* @param att_oculto true o false --mantener campo oculto = true, de lo contrario sera false
* @param att_autocomplete off o on --autocompletar el campo
* @param sect_msj_validation true o false --permitir mensaje de validacion/invalid feedback
* @param clss_section clases del elemento section, contenedor del input
* @param clss_input clases del elemento input
* @param clss_label clases del elemento label
* @param clss_msj_validation clases del elemento section -> invalid-feedback
* @param att_otros objeto {} con propiedad que contiene otros atributos a adicionar en input. ejemplo: {minLength: 10, maxLength: 15}; el nombre de las propiedades deben ser acordes a react JS y no a html
*/


/**
* @param {inputTextCallback}
*/
function Inp_buscar (props){

  const att_name = 'Inp_buscar';
  const att_type = 'text';

  let {
    att_value = '',
    att_maxlength = 50, 
    att_required = false,
    att_disabled = false,
    att_oculto = false,
    att_autocomplete = 'off',
    att_comentarios = '',
    sect_msj_validation = false,
    clss_section = 'form-floating',
    clss_input = 'form-control',
    clss_label = 'form-label',
    clss_msj_validation = 'invalid-feedback',
    att_otros = {},
  } = props;


  if(att_comentarios !== ''){
    att_comentarios = Word(att_comentarios);
  }

  const label_visual = Word('_INP_BUSCADOR');
  const att_title_label = label_visual;
  const att_invalid_feedback = `${label_visual} ${Word('_ES_REQUERIDO')} ${att_comentarios}`;
  const att_placeholder = `${Word('_REGISTRA_VALOR_BUSCAR')}`;
  const att_title = att_placeholder;

  clss_input += ` ${att_name}`;


  if (att_oculto){
    clss_section  += ' obj-oculto';
    clss_input += ' obj-oculto';
    clss_label += ' obj-oculto';
    clss_msj_validation += ' obj-oculto';
  }

  if (!sect_msj_validation){
    clss_msj_validation = ' obj-oculto';
  }



  /////////////////////////
  /////////////////////////
  /////////////////////////

  const [valor, setValor] = useState(att_value || '');

  const regValor = (valor) => {
    setValor(valor);
    
  };
  

  return (
    <>
    <section className={clss_section}>
      <input
      className={clss_input}
      name={att_name}
      type={att_type}
      value={valor}
      onChange={(event) => regValor(event.target.value)}
      placeholder={att_placeholder}
      title={att_title}
      maxLength={att_maxlength}
      disabled={att_disabled}
      required={att_required}
      autoComplete={att_autocomplete}
      {...(att_otros ? att_otros : null)}
      >
      </input>
      <label className={clss_label} htmlFor={att_name} >{att_title_label} </label>
      <section className={clss_msj_validation} >{att_invalid_feedback} </section>
    </section>
    </>
  );
}






/**
* @param {inputTextCallback}
*/
function Inp_valor_moneda (props){

  const att_name = 'Inp_valor_moneda';
  const att_type = 'number';

  let {
    att_value = '',
    att_maxlength = 50, 
    att_required = false,
    att_disabled = false,
    att_oculto = false,
    att_autocomplete = 'off',
    att_comentarios = '',
    sect_msj_validation = false,
    clss_section = 'form-floating',
    clss_input = 'form-control',
    clss_label = 'form-label',
    clss_msj_validation = 'invalid-feedback',
    att_otros = {},
  } = props;


  if(att_comentarios !== ''){
    att_comentarios = Word(att_comentarios);
  }

  const label_visual = Word('_INP_VALOR');
  const att_title_label = label_visual;
  const att_invalid_feedback = `${label_visual} ${Word('_ES_REQUERIDO')} ${att_comentarios}`;
  const att_placeholder = `${Word('_REGISTRA_VALOR_CALCULAR')}`;
  const att_title = att_placeholder;

  clss_input += ` ${att_name}`;


  if (att_oculto){
    clss_section  += ' obj-oculto';
    clss_input += ' obj-oculto';
    clss_label += ' obj-oculto';
    clss_msj_validation += ' obj-oculto';
  }

  if (!sect_msj_validation){
    clss_msj_validation = ' obj-oculto';
  }



  /////////////////////////
  /////////////////////////
  /////////////////////////

  const [valor, setValor] = useState(att_value || '');

  const regValor = (valor) => {
    setValor(valor);
    
  };
  

  return (
    <>
    <section className={clss_section}>
      <input
      className={clss_input}
      name={att_name}
      type={att_type}
      value={valor}
      onChange={(event) => regValor(event.target.value)}
      placeholder={att_placeholder}
      title={att_title}
      maxLength={att_maxlength}
      disabled={att_disabled}
      required={att_required}
      autoComplete={att_autocomplete}
      {...(att_otros ? att_otros : null)}
      >
      </input>
      <label className={clss_label} htmlFor={att_name} >{att_title_label} </label>
      <section className={clss_msj_validation} >{att_invalid_feedback} </section>
    </section>
    </>
  );
}












/**
* @param {inputTextCallback}
*/
function Inp_minutos (props){

  const att_name = 'Inp_minutos';
  const att_type = 'number';

  let {
    att_value = '',
    att_maxlength = 10, 
    att_required = false,
    att_disabled = false,
    att_oculto = false,
    att_autocomplete = 'off',
    att_comentarios = '',
    sect_msj_validation = false,
    clss_section = 'form-floating',
    clss_input = 'form-control',
    clss_label = 'form-label',
    clss_msj_validation = 'invalid-feedback',
    att_otros = {},
  } = props;


  if(att_comentarios !== ''){
    att_comentarios = Word(att_comentarios);
  }

  const label_visual = Word('_INP_MINUTOS');
  const att_title_label = label_visual;
  const att_invalid_feedback = `${label_visual} ${Word('_ES_REQUERIDO')} ${att_comentarios}`;
  const att_placeholder = `${Word('_REGISTRA_NUM_MINUTOS')}`;
  const att_title = att_placeholder;

  clss_input += ` ${att_name}`;


  if (att_oculto){
    clss_section  += ' obj-oculto';
    clss_input += ' obj-oculto';
    clss_label += ' obj-oculto';
    clss_msj_validation += ' obj-oculto';
  }

  if (!sect_msj_validation){
    clss_msj_validation = ' obj-oculto';
  }



  /////////////////////////
  /////////////////////////
  /////////////////////////

  const [valor, setValor] = useState(att_value || '');

  const regValor = (valor) => {
    setValor(valor);
    
  };
  

  return (
    <>
    <section className={clss_section}>
      <input
      className={clss_input}
      name={att_name}
      type={att_type}
      value={valor}
      onChange={(event) => regValor(event.target.value)}
      placeholder={att_placeholder}
      title={att_title}
      maxLength={att_maxlength}
      disabled={att_disabled}
      required={att_required}
      autoComplete={att_autocomplete}
      {...(att_otros ? att_otros : null)}
      >
      </input>
      <label className={clss_label} htmlFor={att_name} >{att_title_label} </label>
      <section className={clss_msj_validation} >{att_invalid_feedback} </section>
    </section>
    </>
  );
}































//input radio//
//input radio//
//input radio//
//input radio//


/**
* @param {inputTextCallback}
*/
function Inp_regimen (props){

  const att_name = 'Inp_regimen';
  const att_type = 'radio';

  let {
    att_required = false,
    att_disabled = false,
    att_oculto = false,
    clss_section = 'input-radio',
    clss_label = 'input-group-text celda1',
    clss_input = 'form-control celda2',
  } = props;
  
  
  const label_visual = Word('_INP_REGIMEN');
  const att_title_label = label_visual;

  
  clss_input += ` ${att_name}`;


  if (att_oculto){
    clss_section  += ' obj-oculto';
    clss_label += ' obj-oculto';
    clss_input += ' obj-oculto';
  }
  


  /////////////////////////
  /////////////////////////
  /////////////////////////

  const [valor, setValor] = useState('');

  const regValor = (valor) => {
    setValor(valor);
  };


  return (
    <>
    <section className={clss_section} >

      <label className={clss_label} >{att_title_label}</label>

      <section className={clss_input} >

        <div className='form-check'>
          <input className='form-check-input' 
          type={att_type} 
          name={att_name} 
          id={`${att_name}_1`} 
          value='contributivo' 
          onChange={(event) => regValor(event.target.value)} 
          required={att_required} 
          disabled={att_disabled} />
          <label className='form-check-label' htmlFor={`${att_name}_1`} >Contributivo</label>
        </div>

        <div className='form-check'>
          <input className='form-check-input' 
          type={att_type} name={att_name} id={`${att_name}_2`} 
          value='subsidiado' 
          onChange={(event) => regValor(event.target.value)} 
          required={att_required} 
          disabled={att_disabled} />
          <label className='form-check-label' htmlFor={`${att_name}_2`} >Subsidiado</label>
        </div>

      </section>

    </section>
   </>
  );
}






/**
* @param {inputTextCallback}
*/
function Inp_clase_afiliado (props){

  const att_name = 'Inp_clase_afiliado';
  const att_type = 'radio';

  let {
    att_required = false,
    att_disabled = false,
    att_oculto = false,
    clss_section = 'input-radio',
    clss_label = 'input-group-text celda1',
    clss_input = 'form-control celda2',
  } = props;
  
  
  const label_visual = Word('_INP_CLASE_AFILIADO');
  const att_title_label = label_visual;

  
  clss_input += ` ${att_name}`;


  if (att_oculto){
    clss_section  += ' obj-oculto';
    clss_label += ' obj-oculto';
    clss_input += ' obj-oculto';
  }
  


  /////////////////////////
  /////////////////////////
  /////////////////////////

  const [valor, setValor] = useState('');

  const regValor = (valor) => {
    setValor(valor);
  };


  return (
    <>
    <section className={clss_section} >

      <label className={clss_label} >{att_title_label}</label>

      <section className={clss_input} >

        <div className='form-check'>
          <input className='form-check-input' 
          type={att_type} 
          name={att_name} 
          id={`${att_name}_1`} 
          value='cotizante' 
          onChange={(event) => regValor(event.target.value)} 
          required={att_required} 
          disabled={att_disabled} />
          <label className='form-check-label' htmlFor={`${att_name}_1`} >Soy cotizante</label>
        </div>

        <div className='form-check'>
          <input className='form-check-input' 
          type={att_type} name={att_name} id={`${att_name}_2`} 
          value='beneficiario' 
          onChange={(event) => regValor(event.target.value)} 
          required={att_required} 
          disabled={att_disabled} />
          <label className='form-check-label' htmlFor={`${att_name}_2`} >Soy beneficiario</label>
        </div>

      </section>

    </section>
   </>
  );
}




/**
* @param {inputTextCallback}
*/
function Inp_rango_nivel (props){

  const att_name = 'Inp_rango_nivel';
  const att_type = 'radio';

  let {
    att_required = false,
    att_disabled = false,
    att_oculto = false,
    clss_section = 'input-radio',
    clss_label = 'input-group-text celda1',
    clss_input = 'form-control celda2',
  } = props;
  
  
  const label_visual = Word('_INP_RANGO_NIVEL');
  const att_title_label = label_visual;

  
  clss_input += ` ${att_name}`;


  if (att_oculto){
    clss_section  += ' obj-oculto';
    clss_label += ' obj-oculto';
    clss_input += ' obj-oculto';
  }
  


  /////////////////////////
  /////////////////////////
  /////////////////////////

  const [valor, setValor] = useState('');

  const regValor = (valor) => {
    setValor(valor);
  };


  return (
    <>
    <section className={clss_section} >

      <label className={clss_label} >{att_title_label}</label>

      <section className={clss_input} >

        <div className='form-check'>
          <input className='form-check-input' 
          type={att_type} 
          name={att_name} 
          id={`${att_name}_1`} 
          value='1' 
          onChange={(event) => regValor(event.target.value)} 
          required={att_required} 
          disabled={att_disabled} />
          <label className='form-check-label' htmlFor={`${att_name}_1`} >Nivel 1</label>
        </div>

        <div className='form-check'>
          <input className='form-check-input' 
          type={att_type} name={att_name} id={`${att_name}_2`} 
          value='2' 
          onChange={(event) => regValor(event.target.value)} 
          required={att_required} 
          disabled={att_disabled} />
          <label className='form-check-label' htmlFor={`${att_name}_2`} >Nivel 2</label>
        </div>

        <div className='form-check'>
          <input className='form-check-input' 
          type={att_type} name={att_name} id={`${att_name}_3`} 
          value='3' 
          onChange={(event) => regValor(event.target.value)} 
          required={att_required} 
          disabled={att_disabled} />
          <label className='form-check-label' htmlFor={`${att_name}_3`} >Nivel 3</label>
        </div>


      </section>

    </section>
   </>
  );
}













































//select//
//select//
//select//
//select//


/**
* @callback inputSelectCallback
* @param att_options objeto como valor de los options, con keys value y descr; ej: {'value': 2023, 'descr': 'soy año 2023'}
* @param att_required true o false --requerido como obligatorio
* @param att_disabled true o false --input habilitado (true) o deshabilitado (false) para diligenciamiento
* @param att_oculto true o false --mantener campo oculto = true, de lo contrario sera false
* @param att_autocomplete off o on --autocompletar el campo
* @param sect_msj_validation true o false --permitir mensaje de validacion/invalid feedback
* @param clss_section clases del elemento section, contenedor del select
* @param clss_select clases del elemento select
* @param clss_label clases del elemento label
* @param clss_msj_validation clases del elemento section -> invalid-feedback
* @param att_otros objeto {} con propiedad que contiene otros atributos a adicionar en input. ejemplo: {minLength: 10, maxLength: 15}; el nombre de las propiedades deben ser acordes a react JS y no a html
*/


/**
* @param {inputSelectCallback}
*/
function Inp_select_año (props){

  const att_name = 'Inp_select_año';
 

  let {
    att_options = [],
    att_required = false,
    att_disabled = false,
    att_oculto = false,
    att_autocomplete = 'off',
    att_comentarios = '',
    sect_msj_validation = true,
    clss_section = 'div-select',
    clss_select = 'form-select celda2',
    clss_label = 'input-group-text celda1',
    clss_msj_validation = 'invalid-feedback celda3',
    att_otros = {},
  } = props;


  
  if(att_comentarios !== ''){
    att_comentarios = Word(att_comentarios);
  }
  
  
  const label_visual = Word('_SELECT_AÑO');
  const att_title_label = label_visual;
  const att_invalid_feedback = `${label_visual} ${Word('_ES_REQUERIDO')} ${att_comentarios}`;
  const att_title = `${Word('_SELECCIONA_AÑO')}`;

  clss_select += ` ${att_name}`;
  

  if (att_oculto){
    clss_section  += ' obj-oculto';
    clss_select += ' obj-oculto';
    clss_label += ' obj-oculto';
    clss_msj_validation += ' obj-oculto';
  }


  
  if (!sect_msj_validation){
    clss_msj_validation = ' obj-oculto';
  }


  /////////////////////////
  /////////////////////////
  /////////////////////////

  const [valor, setValor] = useState([]);
 
  const regValor = (valor) => {
    setValor({'valor': valor.value, 'text': valor.textContent });
  };



  return (
    <>
    <section className={clss_section}>
    <label className={clss_label} htmlFor={att_name} >{att_title_label} </label>
    <select
        
        className={clss_select}
        name={att_name}
        onChange={(event) => regValor(event.target.options[event.target.selectedIndex])}
        title={att_title}
        disabled={att_disabled}
        required={att_required}
        autoComplete={att_autocomplete}
        {...(att_otros ? att_otros : null)}

        >
          <option value=''></option>
          {
            att_options.map((dato, indice) => {
              return (
                <option key={indice} value={dato.value}>{dato.descr}</option>
              )
            })
          }

      </select>

      <section className={clss_msj_validation} >{att_invalid_feedback}</section>
    </section>
    </>
  );
}




























/**
 * 
 * @param att_name nombre
 * @param att_value valor que sera el texto a mostrar en el boton
 * @param att_title titulo/descripcion que se mostrará al pasar el mouse
 * @param att_disabled true o false --input habilitado (true) o deshabilitado (false)
 * @param att_oculto true o false --mantener btn oculto = true, de lo contrario sera false
 * @param att_style ej: btn-primary(azul), btn-secondary(gris), btn-success(verde), btn-danger(rojo), btn-warning(amarillo), btn-info(celeste), btn-light(blanco), btn-dark(negro) /// btn-outline-primary, btn-outline-secondary, btn-outline-success, btn-outline-danger, btn-outline-warning, btn-outline-info, btn-outline-light, btn-outline-dark etc..
 * @param att_size btn-lg, btn-sm
 * @param att_width ancho en (%) w-0, w-25, w-50, w-75, w-100
 * @param svg_ico nombre de icono. ej: settings_fillsvg
 * @param svg_color color icono ej: svg-color-claro, svg-color-oscuro, svg-color-claro-forced, svg-color-oscuro-forced
 * @param text_color color texto ej: color-claro, color-oscuro, color-claro-forced, color-oscuro-forced
 * @param btn_redondo true o false --redondo (true) o cuadrado (false)
 * @param btn_modelo modelo de boton: 1 = solo texto; 2 = icono + texto; 3 = solo icono
 * @param clss_btn clases del elemento button 
 * @param clss_span clases del elemento span
 * @param clss_p clases del elemento p
 * @param otr_atributos ej: otros atributos etc...
 */
function Inp_btn_constructor (props){

  const att_type = 'submit';

  let {
    att_name = 'Inp_btn',
    att_value = '',
    att_title = '',
    att_disabled = false,
    att_oculto = false,
    att_style = 'btn-secondary',
    att_size = 'btn-lg',
    att_width = 'w-0',
    svg_ico = 'settings_fillsvg',
    svg_color = 'svg-color-claro',
    text_color = 'color-claro',
    btn_redondo = false,
    btn_modelo = 2,
    clss_btn = 'btn btn-ico',
    clss_span = '',
    clss_p = '',
    att_otros = {},
  } = props;


  if (btn_redondo){
    clss_btn += ' radius50';
  }

  clss_btn += ` ${att_style} ${att_size} ${att_width} ${att_name}`;
  clss_span += `${svg_ico} ${svg_color}`;
  clss_p += `${text_color}`;

  if (att_oculto){
    clss_btn  += ' obj-oculto';
    clss_span += ' obj-oculto';
    clss_p += ' obj-oculto';
  }


  att_value = Word(att_value);
  att_title = Word(att_title);



  switch (btn_modelo) {
    case 1:
      clss_span += ' obj-oculto';
      break;
    case 3:
      clss_p += ' obj-oculto';
      break;
    default:
      break;
  }



  /////////////////////////
  /////////////////////////
  /////////////////////////

  return (
    <>
    <button
    className={clss_btn}
    name={att_name}
    type={att_type}
    value={att_value}
    title={att_title}
    disabled={att_disabled}
    onClick={props.onClick}
  
    {...(att_otros ? att_otros : null)}
    >
      <span className={clss_span}></span>
      <p className={clss_p}>{att_value}</p>
    </button>
    </>
  );
}





export {
  Inp_buscar,
  Inp_valor_moneda, 
  Inp_regimen, 
  Inp_clase_afiliado, 
  Inp_rango_nivel, 
  Inp_select_año,
  Inp_btn_constructor,
  Inp_minutos
};
