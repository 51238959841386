import React, { useEffect, useState } from 'react';

import { Head } from '../../../utils/Head';
import { Inp_minutos, Inp_btn_constructor } from '../../../utils/inputs';

import { useIdioma } from '../../../context/IdiomaProvider';




function Cronometro(props) {

  const { vlrWord } = useIdioma();
  
  const [horas, setHoras] = useState(0);

  const [minutos, setMinutos] = useState(0);

  const [segundos, setSegundos] = useState(0);

  const [milisegundos, setMilisegundos] = useState(0);




  const [unixInicio, setUnixInicio] = useState(0);

  const [unixFin, setUnixFin] = useState(0);













useEffect(() => {

  if ((unixFin - unixInicio) > 0) {

    // Configura un temporizador para disminuir el tiempo restante cada segundo
    const timerId = setInterval(() => {

      let contador = unixFin - unixInicio;
      setUnixInicio(Date.now());

      
      setHoras(Math.floor(contador / 3600000));
      setMinutos(Math.floor((contador % 3600000) / 60000));
      setSegundos(Math.floor((contador % 60000) / 1000));
      setMilisegundos(contador % 1000);
  
    }, 10);


    // Limpia el temporizador al desmontar el componente o cuando el tiempo restante cambie
    return () => clearInterval(timerId);

  } else {
    setHoras(0);
    setMinutos(0);
    setSegundos(0);
    setMilisegundos(0);
  }

}, [unixInicio]);









  const sendForm = (event) => {

    event.preventDefault();


    let objForm = document.querySelector(".form-buscar");

    objForm.classList.add('was-validated');
    
    if (!objForm.reportValidity()){
      return;
    }


    let objInput = document.querySelector('.Inp_minutos');

    let ms = (parseInt(objInput.value) * 60000);

    setUnixInicio(Date.now());

    setUnixFin(Date.now() + ms);


  }; 













  
  return (
    <>
      <Head title={props.titulo} description={props.tituloDescr} />
     
      <section className='caja3-block'>
        <h3>{props.titulo}</h3>
        <p>[{props.grupo}]</p>
        <p>{props.tituloDescr}</p>
      </section>

 
      <section className='caja2-block'>
        <form className='form-buscar' >

        <Inp_minutos att_required={true} sect_msj_validation={true} att_otros={{minLength: 1, min: 1, max: 44640}} att_comentarios={'_CRONOMETRO_EJEMPLO'} />

        <Inp_btn_constructor 
        clss_btn={'btn btn-ico'}
        att_name={'Inp_btn_play'} 
        att_value={'_BTN_INICIAR'} 
        att_title={'_BTN_INICIAR'} 
        btn_modelo={3}
        att_style={'btn-light'}
        svg_ico={'playsvg'}
        svg_color={'svg-color-oscuro-forced'}
        btn_redondo={true}


        onClick={(event) => sendForm(event)} 
        />

        </form>
      </section>



      <section className='caja2-block container-cronometro'>

      <section className='digital'>

        <section className='digital-bloque'>
          <span className='horas'>{horas}</span>
          <span>{vlrWord('_HORAS')}</span>
        </section>

        <section className='digital-bloque'>
          <span className='minutos'>{minutos}</span>
          <span>{vlrWord('_MINUTOS')}</span>
        </section>

        <section className='digital-bloque'>
          <span className='segundos'>{segundos}</span>
          <span>{vlrWord('_SEGUNDOS')}</span>
        </section>

        <section className='digital-bloque'>
          <span className='milisegundos'><span>{milisegundos}</span></span>
          <span>{vlrWord('_MILISEGUNDOS')}</span>
        </section>

      </section>

      </section>

    </>
    
  );


}

export { Cronometro };
