import React from 'react';

import { Helmet, HelmetProvider } from 'react-helmet-async';

import { useIdioma } from '../context/IdiomaProvider';



function Head(props) {

  const { vlrWord } = useIdioma();

  return (
    <HelmetProvider>
      <Helmet>
        <title>{props.title}</title>
        <link rel="icon" type="image/x-icon" href='favicon.ico' />

        <meta charset={vlrWord('_META_CHARSET')} />
        <meta http-equiv='X-UA-Compatible' content='IE=edge' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <meta name='author' content={vlrWord('_META_AUTHOR')} />
        <meta name='copyright' content={vlrWord('_META_COPYRIGHT')} />
        <meta name='title' content={props.title} />
        <meta name='description' content={props.description} />

      </Helmet>
    </HelmetProvider>
  )
}


export { Head };


/*
<meta name="google-adsense-account" content="ca-pub-7937514976213608" />
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7937514976213608" crossorigin="anonymous"></script>
*/