const palabra = [];

palabra.push({key: "_META_CHARSET", value: "utf-8"});
palabra.push({key: "_META_AUTHOR", value: "Fabio Hernandez Mancipe"});
palabra.push({key: "_META_VERSION", value: "V.1.24"});
palabra.push({key: "_META_COPYRIGHT", value: "Fabio Hernandez Mancipe"});
palabra.push({key: "_LOGO_SLOGAN", value: "Trivial knowledge you never thought you would need\nEssential knowledge... for unpredictable situations."});
palabra.push({key: "_FOOTER_INFO_TITULO", value: "All rights reserved Fabio H. Mancipe ©."});
palabra.push({key: "_FOOTER_INFO_CONTENIDO", value: "Fancipe, your compass in the world of learning, explores Knowledge in all its forms; Trivial knowledge you never thought you'd need; Essential Knowledge... for Unpredictable Situations."});
palabra.push({key: "_FOOTER_INFO_QUIENES_SOMOS", value: "About us"});
palabra.push({key: "_FOOTER_INFO_COOKIES", value: "Cookies, terms and conditions of use"});
palabra.push({key: "_FOOTER_INFO_HABEAS_DATA", value: "Personal data protection policy"});
palabra.push({key: "_FOOTER_INFO_WEB", value: "website+"});
palabra.push({key: "_FOOTER_INFO_MAIL", value: "mail+"});
palabra.push({key: "_FOOTER_CONTACTO_TITULO", value: "CONTACT"});
palabra.push({key: "_FOOTER_CONTACTO_PAIS", value: "Colombia, SurAmérica"});
palabra.push({key: "_FOOTER_CONTACTO_ZONA", value: "Mosquera, Cundinamarca"});
palabra.push({key: "_FOOTER_CONTACTO_MAIL", value: "info@fancipe.com"});
palabra.push({key: "_FOOTER_CONTACTO_TEL", value: "(57)+(601)+3013558565"});
palabra.push({key: "_DATATABLES_DECIMAL", value: "."});
palabra.push({key: "_DATATABLES_EMPTYTABLE", value: "Empty table"});
palabra.push({key: "_DATATABLES_INFO", value: "Showing _START_ to _END_ of _TOTAL_ entries"});
palabra.push({key: "_DATATABLES_INFOEMPTY", value: "No information"});
palabra.push({key: "_DATATABLES_INFOFILTERED", value: "Filtering _MAX_ total entries"});
palabra.push({key: "_DATATABLES_THOUSANDS", value: ","});
palabra.push({key: "_DATATABLES_LENGTHMENU", value: "View _MENU_ Entries"});
palabra.push({key: "_DATATABLES_LOADINGRECORDS", value: "Loading…"});
palabra.push({key: "_DATATABLES_PROCESSING", value: "Processing..."});
palabra.push({key: "_DATATABLES_SEARCH", value: "Search:"});
palabra.push({key: "_DATATABLES_ZERORECORDS", value: "No information found"});
palabra.push({key: "_DATATABLES_PAGINATE_FIRST", value: "first"});
palabra.push({key: "_DATATABLES_PAGINATE_LAST", value: "last"});
palabra.push({key: "_DATATABLES_PAGINATE_NEXT", value: "next"});
palabra.push({key: "_DATATABLES_PAGINATE_PREVIOUS", value: "previous"});
palabra.push({key: "_DATATABLES_ARIA_SORT_ASCENDING", value: ": Activate to sort ascending"});
palabra.push({key: "_DATATABLES_ARIA_SORT_DESCENDING", value: ": Activate to sort descending"});
palabra.push({key: "_COOKIES_MODAL_TITULO", value: "Acceptance of cookies"});
palabra.push({key: "_COOKIES_MODAL", value: "To continue browsing our site, you must accept the use of cookies"});
palabra.push({key: "_COOKIES_MODAL_RECHAZAR", value: "Unfortunately you cannot continue browsing our site; Please close this tab manually in your browser."});
palabra.push({key: "_ERR404_MSJ1", value: "The requested page is not on the server"});
palabra.push({key: "_ERR404_MSJ2", value: "If someone has a hundred sheep and loses one, doesn't he leave the ninety-nine in the field and goes to look for the one that was lost until he finds it? And when he finds it, he carries it on his shoulders, full of joy, and when he gets home he calls his friends and neighbors, and says to them: Rejoice with me, because I have found the sheep that was lost. I assure you that, in the same way, there will be more joy in heaven over one sinner who is converted, than over ninety-nine righteous people who do not need to be converted."});
palabra.push({key: "_MENU_SALUD", value: "Health"});
palabra.push({key: "_MENU_FINANZAS", value: "Personal Finance"});
palabra.push({key: "_MENU_INGENIERIA", value: "engineering"});
palabra.push({key: "_MENU_ESTADISTICAS", value: "Statistics"});
palabra.push({key: "_MENU_UTILIDADES", value: "Utilities"});
palabra.push({key: "_INICIO", value: "Home"});
palabra.push({key: "_INICIO_DESCR", value: "Welcome to Fancipe"});
palabra.push({key: "_GRUPO", value: "Group"});
palabra.push({key: "_TITULO", value: "Qualification"});
palabra.push({key: "_TITULO_DESCR", value: "Description"});
palabra.push({key: "_CONSULTA_DIVIPOLA", value: "DIVIPOLA Consultations"});
palabra.push({key: "_CONSULTA_DIVIPOLA_DESCR", value: "Consultations of the political-administrative division of Colombia - DIVIPOLA, Tool for consulting department codes and official municipality codes of the government of Colombia, generated and updated by DANE"});
palabra.push({key: "_CONSULTA_DIVIPOLA_EJEMPLO", value: "ejemplo: Cundinamarca"});
palabra.push({key: "_CONSULTA_CIUO", value: "CIUO consultations"});
palabra.push({key: "_CONSULTA_CIUO_DESCR", value: "Consultation tool for the International Standard Classification of Occupations CIUO according to DANE and adapted for Colombia"});
palabra.push({key: "_CONSULTA_CIUO_EJEMPLO", value: "ejemplo: Auxiliar"});
palabra.push({key: "_CONSULTA_ISS2001", value: "Consultations agreement 256 of 2001 - ISS2001"});
palabra.push({key: "_CONSULTA_ISS2001_DESCR", value: "Consultations on the health tariff manual of the ISS Social Security Institute - agreement 256 of 2001 (ISS2001); Warning: Agreement 256 of 2001 does NOT have legal validity in the general social health security system in Colombia (SGSSS); Its importance lies in the method of settlement of contracting services in the private market, between payers and providers."});
palabra.push({key: "_CONSULTA_ISS2001_EJEMPLO", value: "ejemplo: Consulta"});
palabra.push({key: "_CONSULTA_CUPS_R2238_2020", value: "Consultations CUPS R2238 of 2020 and R126 of 2021"});
palabra.push({key: "_CONSULTA_CUPS_R2238_2020_DESCR", value: "Valid 01/01/2023 to 31/12/2021; It corresponds to a detailed organization of health services (consultations, laboratories, therapies, outpatient and hospital procedures, specialized examinations, among others), which are carried out in the Republic of Colombia, in compliance with the principles of interoperability and data standardization, using for the identification of a code and a description validated by the country's committee of experts, regardless of the profession or discipline of the health sector that performs them, as well as the scope of their implementation; It is important to note that the services described are NOT part of the health insurance coverage, that is, a service may or may NOT be covered by the health system."});
palabra.push({key: "_CONSULTA_CUPS_R2238_2020_EJEMPLO", value: "ejemplo: Consulta"});
palabra.push({key: "_CONSULTA_CUPS_R2077_2021", value: "Consultations CUPS R2077 of 2021"});
palabra.push({key: "_CONSULTA_CUPS_R2077_2021_DESCR", value: "Valid 01/01/2022 to 31/12/2022; It corresponds to a detailed organization of health services (consultations, laboratories, therapies, outpatient and hospital procedures, specialized examinations, among others), which are carried out in the Republic of Colombia, in compliance with the principles of interoperability and data standardization, using for the identification of a code and a description validated by the country's committee of experts, regardless of the profession or discipline of the health sector that performs them, as well as the scope of their implementation; It is important to note that the services described are NOT part of the health insurance coverage, that is, a service may or may NOT be covered by the health system."});
palabra.push({key: "_CONSULTA_CUPS_R2077_2021_EJEMPLO", value: "ejemplo: Consulta"});
palabra.push({key: "_CONSULTA_CUPS_R2775_2022", value: "Consultations CUPS R2775 of 2022"});
palabra.push({key: "_CONSULTA_CUPS_R2775_2022_DESCR", value: "Valid from 01/01/2023; It corresponds to a detailed organization of health services (consultations, laboratories, therapies, outpatient and hospital procedures, specialized examinations, among others), which are carried out in the Republic of Colombia, in compliance with the principles of interoperability and data standardization, using for the identification of a code and a description validated by the country's committee of experts, regardless of the profession or discipline of the health sector that performs them, as well as the scope of their implementation; It is important to note that the services described are NOT part of the health insurance coverage, that is, a service may or may NOT be covered by the health system."});
palabra.push({key: "_CONSULTA_CUPS_R2775_2022_EJEMPLO", value: "ejemplo: Consulta"});
palabra.push({key: "_CONSULTA_CUPS_R2336_2023", value: "Consultations CUPS R2336 of 2023"});
palabra.push({key: "_CONSULTA_CUPS_R2336_2023_DESCR", value: "Valid from 01/01/2024; It corresponds to a detailed organization of health services (consultations, laboratories, therapies, outpatient and hospital procedures, specialized examinations, among others), which are carried out in the Republic of Colombia, in compliance with the principles of interoperability and data standardization, using for the identification of a code and a description validated by the country's committee of experts, regardless of the profession or discipline of the health sector that performs them, as well as the scope of their implementation; It is important to note that the services described are NOT part of the health insurance coverage, that is, a service may or may NOT be covered by the health system."});
palabra.push({key: "_CONSULTA_CUPS_R2336_2023_EJEMPLO", value: "ejemplo: Consulta"});
palabra.push({key: "_LIQUIDADOR_COPAGOS", value: "Copay Adjuster"});
palabra.push({key: "_LIQUIDADOR_COPAGOS_DESCR", value: "The Ministry of Health of the Republic of Colombia establishes the system of shared payments and moderating fees in the health system; Below we allow ourselves to share a co-payment adjuster, following the corresponding guidelines established by the aforementioned ministry."});
palabra.push({key: "_CRONOMETRO", value: "Chronometer"});
palabra.push({key: "_CRONOMETRO_DESCR", value: "Nothing more to say, it's just a stopwatch."});
palabra.push({key: "_CRONOMETRO_EJEMPLO", value: "Minimum 1"});
palabra.push({key: "_INP_BUSCADOR", value: "Seeker"});
palabra.push({key: "_INP_REGIMEN", value: "Regime"});
palabra.push({key: "_INP_CLASE_AFILIADO", value: "Affiliate class"});
palabra.push({key: "_INP_RANGO_NIVEL", value: "Rank/Level"});
palabra.push({key: "_INP_VALOR", value: "Value"});
palabra.push({key: "_SELECT_AÑO", value: "Year"});
palabra.push({key: "_INP_MINUTOS", value: "Minutes"});
palabra.push({key: "_BTN_BUSCAR", value: "Search"});
palabra.push({key: "_BTN_ENVIAR", value: "Send"});
palabra.push({key: "_BTN_VER", value: "See"});
palabra.push({key: "_BTN_CERRAR", value: "Close"});
palabra.push({key: "_BTN_CALCULAR", value: "Calculate"});
palabra.push({key: "_BTN_INICIAR", value: "Start"});
palabra.push({key: "_REGISTRA_VALOR_BUSCAR", value: "Register value to search"});
palabra.push({key: "_SELECCIONA_AÑO", value: "Select the desired year"});
palabra.push({key: "_REGISTRA_VALOR_CALCULAR", value: "Register value to calculate"});
palabra.push({key: "_NUMERO_ENTERO_DECIMAL", value: "Registers integer or decimal number separated by period"});
palabra.push({key: "_REGISTRA_NUM_MINUTOS", value: "Records number of minutes"});
palabra.push({key: "_REFERENCIAS", value: "References"});
palabra.push({key: "_REGISTRA", value: "Register"});
palabra.push({key: "_SELECCIONA", value: "Choose"});
palabra.push({key: "_ES_REQUERIDO", value: "it is required"});
palabra.push({key: "_INFO", value: "Information"});
palabra.push({key: "_ACEPTAR", value: "Accept"});
palabra.push({key: "_RECHAZAR", value: "Decline"});
palabra.push({key: "_AYUDA", value: "Help"});
palabra.push({key: "_SIN_COINCIDENCIAS_ENCONTRADAS", value: "No matches found"});
palabra.push({key: "_INFO_COP_COTIZANTES", value: "Contributing members DO NOT pay copayment; The value corresponds to the moderator fee"});
palabra.push({key: "_INFO_COP_TOPE", value: "The settled value has exceeded the stipulated limit per event, therefore the co-payment value is the maximum limit per event"});
palabra.push({key: "_RESULTADO", value: "Result"});
palabra.push({key: "_VLR_COP", value: "Copay value"});
palabra.push({key: "_VLR_LIQUIDACION", value: "Settlement Base Value"});
palabra.push({key: "_HORAS", value: "Hours"});
palabra.push({key: "_MINUTOS", value: "Minutes"});
palabra.push({key: "_SEGUNDOS", value: "Seconds"});
palabra.push({key: "_MILISEGUNDOS", value: "Milliseconds"});

export { palabra };
