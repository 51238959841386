import React, { createContext, useState, useEffect, useContext } from "react";

import { LocalGet, LocalSet } from '../utils/Sesiones';
import { palabra as wordES } from '../utils/lang/es';
import { palabra as wordEN } from '../utils/lang/en';

import { rutasNav } from '../router';



const IdiomaContext = createContext();

const setLocalSession = async (nombre, dato) => {
  await LocalSet(nombre, dato);
};



function IdiomaProvider ({ children }){

  //idioma usuario
  const [lang, setLang] = useState(null);
  //lib idioma
  const [word, setWord] = useState([]);
  //menu navegacion
  const [menuNav, setMenuNav] = useState(null);

  const [tagsDataTables, setTagsDataTables] = useState([]);

  //establecer lenguaje
  const setLenguaje = function(dato){

    switch (dato) {
      case 'es':
        setWord(wordES);
        setLang('es');
        setLocalSession('lang', 'es');
        break;
      case 'en':
        setWord(wordEN);
        setLang('en');
        setLocalSession('lang', 'en');
        break;
      default:
        setWord(wordES);
        setLang('es');
        setLocalSession('lang', 'es');
        break;
    }

  }




  //buscar valor de palabra, segun lang de usuario 
  const vlrWord = function(buscar){
    let resultado = null;
    resultado = word.filter((dato) => dato.key === buscar);
    if (!resultado.length){
      return null;
    }
    return resultado[0]['value'];
  }
  

  //default
  useEffect(() => {
    
    const validarLocalSession = async () => {
      const idiom = await LocalGet('lang');
      if (!idiom){
        setLenguaje('es');
      } else {
        setLenguaje(idiom);
      }

    };

    validarLocalSession();
  }, []);




  //construir menu, segun lang de usuario 
  useEffect(() => {

    const menu = rutasNav.map((dato) => {
        let rtitulo = vlrWord(dato.titulo);
        let rtituloDescr = vlrWord(dato.tituloDescr);
        let rgrupo = vlrWord(dato.grupo);

        if (rtitulo != null) { dato = {...dato, titulo: rtitulo }; }
        if (rtituloDescr != null) { dato = {...dato, tituloDescr: rtituloDescr }; }
        if (rgrupo != null) { dato = {...dato, grupo: rgrupo }; }

        return dato;
    });

    setMenuNav(menu);
  }, [lang] );
  


  
  //construir etiquetas para DataTables 
  useEffect(() => {
    const tags = {
      "decimal":        vlrWord('_DATATABLES_DECIMAL'),
      "emptyTable":     vlrWord('_DATATABLES_EMPTYTABLE'),
      "info":           vlrWord('_DATATABLES_INFO'),
      "infoEmpty":      vlrWord('_DATATABLES_INFOEMPTY'),
      "infoFiltered":   vlrWord('_DATATABLES_INFOFILTERED'),
      "thousands":      vlrWord('_DATATABLES_THOUSANDS'),
      "lengthMenu":     vlrWord('_DATATABLES_LENGTHMENU'),
      "loadingRecords": vlrWord('_DATATABLES_LOADINGRECORDS'),
      "processing":     vlrWord('_DATATABLES_PROCESSING'),
      "search":         vlrWord('_DATATABLES_SEARCH'),
      "zeroRecords":    vlrWord('_DATATABLES_ZERORECORDS'),
      "paginate": {
          "first":      vlrWord('_DATATABLES_PAGINATE_FIRST'),
          "last":       vlrWord('_DATATABLES_PAGINATE_LAST'),
          "next":       vlrWord('_DATATABLES_PAGINATE_NEXT'),
          "previous":   vlrWord('_DATATABLES_PAGINATE_PREVIOUS')
      },
      "aria": {
          "sortAscending":  vlrWord('_DATATABLES_ARIA_SORT_ASCENDING'),
          "sortDescending": vlrWord('_DATATABLES_ARIA_SORT_DESCENDING'),
      }

    };

    setTagsDataTables(tags);
  }, [lang] );



  
  return (
    <IdiomaContext.Provider value={{
      word: word, 
      vlrWord: vlrWord,
      lang: lang,
      setLenguaje: setLenguaje, 
      menuNav: menuNav,
      tagsDataTables: tagsDataTables,
    }} >
      { children }
    </IdiomaContext.Provider>
  );
}



function useIdioma (){
  const objIdioma = useContext(IdiomaContext);
  return objIdioma;
}




export { IdiomaProvider, useIdioma };
