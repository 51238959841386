import React, { useState, useEffect } from 'react';

import { useIdioma } from '../../context/IdiomaProvider';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';



/**
* listado de props requeridas
* @param titulo titulo modal
* @param msjModal array con el contenido a mostrar.
* @param setMsjModal setMsjModal() es la funcion modificadora de msjModal, y su proposito es ser seteado limpiado una vez se visualice y cierre modal
* @param color_caja color header de caja modal
* * bg-primary(azul), bg-secondary(gris), bg-success(verde), bg-danger(rojo), bg-warning(amarillo), bg-info(celeste), bg-light(blanco), bg-dark(negro)
* @param color_letra text-white(blanco), text-dark(negro)
*/
function ModalBasico(props) {

  const { vlrWord } = useIdioma();

  const [verModal, setverModal] = useState(false);



  const desplegarModal = () => setverModal(true);
  
  const cerrarModal = () => {
    props.setMsjModal([]);
    setverModal(false);
 
  };

  


  useEffect(() => {
    desplegarModal(true);
  }, []);  




  return (
    <>
      <Modal 
      size="lg"
      scrollable={true}
      show={verModal} 
      onHide={cerrarModal}
      >
        <Modal.Header closeButton className={`${props.color_caja} ${props.color_letra}`}>
          <Modal.Title >{vlrWord(props.titulo)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            props.msjModal.map((dato, indice) => {
              return (
                <span key={indice}>
                {vlrWord(dato)}<br/>
                </span>
              )
            })
          }
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={cerrarModal}>{vlrWord('_BTN_CERRAR')}</Button>
        </Modal.Footer>
        
      </Modal>
    </>
  );
}


export { ModalBasico };


