import React, { useState, useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


import { useIdioma } from '../../context/IdiomaProvider';
import { Cookies } from '../../pages/all/Info/Info';
import { CookieSet, CookieGet } from '../../utils/Sesiones';






function ModalCookies() {

  const { vlrWord } = useIdioma();

  const [verModal, setverModal] = useState(false);

  const [infoCookie, setInfoCookie] = useState(false);

  const [cookieMsj, setCookieMsj] = useState('_COOKIES_MODAL');

  const [colorHeader, setColorHeader] = useState('bg-secondary');






  ///////////

  const revCookie = async () => {
    let valor = await CookieGet('aceptacionCookies');
    if(!valor){
      setverModal(true);
    } else {
      setverModal(false);
    }
  };
  

  const regCookie = async () => {
    await CookieSet('aceptacionCookies', 'aceptado', 2, 'dias');
  };
    

  ///////////


  const aceptar = () => {
    regCookie();
    setverModal(false);
  };

  const rechazar = () => {
    setInfoCookie(false);
    setCookieMsj('_COOKIES_MODAL_RECHAZAR');
    setColorHeader('bg-danger');
  };
  
  const ayuda = () => {
    setInfoCookie(true);
    setColorHeader('bg-warning');
  };


  useEffect(() => {
    revCookie();
  } );  
  






  return (
    <>
      <Modal 
      size="lg"
      scrollable={true}
      show={verModal} 
      onHide={rechazar}
      >
        <Modal.Header closeButton className={`${colorHeader} text-white`}>
          <Modal.Title >{vlrWord('_COOKIES_MODAL_TITULO')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        {infoCookie ? <Cookies /> : vlrWord(cookieMsj) }

        </Modal.Body>

        <Modal.Footer>
          <Button variant="success" onClick={aceptar}>{vlrWord('_ACEPTAR')}</Button>
          <Button variant="danger" onClick={rechazar}>{vlrWord('_RECHAZAR')}</Button>
          <Button variant="warning" onClick={ayuda}>{vlrWord('_AYUDA')}</Button>
        </Modal.Footer>
        
      </Modal>
    </>
  );
}




export { ModalCookies };


