import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Head } from '../utils/Head';
import { useIdioma } from '../context/IdiomaProvider';

import { Inp_buscar, Inp_btn_constructor } from '../utils/inputs';




function Home(props) {

  const { menuNav, vlrWord } = useIdioma();

  const [menuFiltrado, setMenuFiltrado] = useState(menuNav);

  useEffect(() => {
    setMenuFiltrado(menuNav);
  }, [menuNav]);



  ////////////////
  ////////////////
  ////////////////
  


  const filtrar = (valor) => {

    const Reg = new RegExp(valor, 'gi');

    const filtro = menuNav.filter((dato)=>{
      if ((dato.titulo && dato.titulo.match(Reg)) || (dato.tituloDescr && dato.tituloDescr.match(Reg)))
      {
        return dato;
      }
    });

    setMenuFiltrado(filtro);
  };




  const sendForm = (event) => {
    event.preventDefault();

    let objForm = document.querySelector(".form-buscar");

    objForm.classList.add('was-validated');
    
    if (!objForm.reportValidity()){
      return;
    }

    let objInput = document.querySelector('.Inp_buscar');

    filtrar(objInput.value);
  }; 




  return (

    <>
    <Head title={props.tituloDescr} description={props.tituloDescr} />

      <section className='caja3-block'>
        <h1>{props.titulo}</h1>
      </section>



    <section className='caja2-block'>

      <form className='form-buscar' >

      <Inp_buscar att_required={false} />


      <Inp_btn_constructor 
      clss_btn={'btn btn-ico'}
      att_name={'Inp_btn_buscar'} 
      att_value={'_BTN_BUSCAR'} 
      att_title={'_BTN_BUSCAR'} 
      btn_modelo={3}
      att_style={'btn-light'}
      svg_ico={'searchsvg'}
      svg_color={'svg-color-oscuro-forced'}
      btn_redondo={true}
  

      onClick={(event) => sendForm(event)} 
      />

      </form>


    </section>





    {
      menuFiltrado.map((dato) => {

        if(dato.path_to === '/'){
          return;
        } 
        else {

          return (  
            <div key={dato.path_to}>
            <>
            <NavLink to={dato.path_to} className='NavLink'>

              <section className='caja1-block'>
              <section className='seccion-menu-titulo'>
                <p>{dato.titulo}</p>
              </section>
        
              <section className='seccion-menu'>
                <section>{vlrWord('_TITULO_DESCR')}</section>
                <p>{dato.tituloDescr}</p>
              </section>
        
              <div className='seccion-menu'>
                <section>{vlrWord('_GRUPO')}</section>
                <p>{dato.grupo}</p>
              </div>
            </section>

          </NavLink>
          </>
          </div>
          )
        }
    })
    }
    </>
  );

}




export { Home };



