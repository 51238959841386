import React from 'react';

import { useIdioma } from '../context/IdiomaProvider';


function Err404() {

  const { vlrWord } = useIdioma();

  return (
    <section className='caja3-block error404'>
    <p>Error 404</p>
    <p>{vlrWord('_ERR404_MSJ1')}</p>
    <span className={`sagrado_corazonsvg`}></span>
    <p>{vlrWord('_ERR404_MSJ2')}</p>
    <p>Lucas 15:4-7</p>
    </section>
  );
}

export { Err404 };
