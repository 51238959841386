import React from 'react';

import { useIdioma } from '../../context/IdiomaProvider';


function LogoHome(props) {

  const { vlrWord } = useIdioma();


  let frase = vlrWord('_LOGO_SLOGAN');

  if (!frase){
    return;
  }

  const paragrafo = frase.split('\n').map((paragraph, index) => (
    <React.Fragment key={index}>{paragraph}<br/></React.Fragment>
  ));

 
  return (
    <>
    <section className="logo-home">
      <section>
        <span>f</span>
        <span>a</span>
        <span>n</span>
        <span>c</span>
        <span>i</span>
        <span>p</span>
        <span>e</span>
        <span>.</span>
        <span>c</span>
        <span>o</span>
        <span>m</span>
      </section>
      <p>{paragrafo}</p>
    </section>

    </>
  );
}

export { LogoHome };

