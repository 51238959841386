
/**
* @param nombre nombre de la cookie
* @param dato dato/valor de la cookie
* @param cantidad en entero positivo
* @param  ref referencia de cantidad; opciones: dias, horas, minutos, segundos
*/
async function CookieSet(nombre, dato, cantidad, ref) {

    let mls;

    switch (ref) {
      case 'dias':
        mls =  cantidad * 24 * 60 * 60;
        break;
      case 'horas':
        mls =  cantidad * 60 * 60;
        break;
      case 'minutos':
        mls =  cantidad * 60;
        break;
      case 'segundos':
        mls =  cantidad;
        break;
      default:
        mls = 1;
        break;
    }

    document.cookie = `${nombre}=${dato}; max-age=${mls}; path=/`;
}



async function CookieGet(nombre) {

  const cookieName = nombre + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return null;
}


async function CookieDelete(nombre) {
  document.cookie = nombre + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}



//////////////////////
//////////////////////
//////////////////////
//////////////////////


/**
* @param nombre nombre de la sessionStorage
* @param dato dato/valor de la sessionStorage
*/
async function SessionSet(nombre, dato) {
  // Almacenar en sessionStorage
  sessionStorage.setItem(nombre, dato);
}


async function SessionGet(nombre) {
  // Obtener desde sessionStorage
  return sessionStorage.getItem(nombre);
}

async function SessionDelete(nombre) {
  // Eliminar desde sessionStorage
  sessionStorage.removeItem(nombre);
}

async function SessionDeleteAll() {
  // Eliminar todas las sessionStorage
  sessionStorage.clear();
}





/**
* @param nombre nombre de la sessionStorage
* @param dato dato/valor de la sessionStorage
*/
async function LocalSet(nombre, dato) {
  // Almacenar en localStorage
  localStorage.setItem(nombre, dato);
}


async function LocalGet(nombre) {
  // Obtener desde localStorage
  return localStorage.getItem(nombre);
}

async function LocalDelete(nombre) {
  // Eliminar desde localStorage
  localStorage.removeItem(nombre);
}

async function LocalDeleteAll() {
  // Eliminar todas las localStorage
  localStorage.clear();
}



export { 
  CookieSet, 
  CookieGet, 
  CookieDelete, 
  SessionSet, 
  SessionGet, 
  SessionDelete, 
  SessionDeleteAll,  
  LocalSet, 
  LocalGet, 
  LocalDelete, 
  LocalDeleteAll,  
};



