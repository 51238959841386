import * as XLSX from 'xlsx';


/**
* @param urlexcel ej: '/assets/files/divipola/divipola.xlsx'
* @param numHoja numero de la hoja, iniciando desde 0; ej:0,1,2,3,etc...
* @return contenido de hoja en JSON
*/
async function Excel_to_JSON (urlexcel, numHoja){


  try {
      //solicitar el excel 
      const response = await fetch(urlexcel); 

      //pasar excel a arrayBuffer 
      const arrayBuffer = await response.arrayBuffer();

      //libro de trabajo
      const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' });

      //nombre hoja
      const sheetName = workbook.SheetNames[numHoja];

      //contenido hoja
      const sheet = workbook.Sheets[sheetName];

      const rJSON = XLSX.utils.sheet_to_json(sheet, {raw: false});

      return rJSON;

    } catch (error) {
      console.error('Error al cargar el archivo;', error);
      return null;
    }
}



export { Excel_to_JSON };



