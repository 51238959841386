const palabra = [];

palabra.push({key: "_META_CHARSET", value: "utf-8"});
palabra.push({key: "_META_AUTHOR", value: "Fabio Hernandez Mancipe"});
palabra.push({key: "_META_VERSION", value: "V.1.24"});
palabra.push({key: "_META_COPYRIGHT", value: "Fabio Hernandez Mancipe"});
palabra.push({key: "_LOGO_SLOGAN", value: "Conocimiento trivial que nunca pensaste que necesitarías\nConocimientos imprescindibles... para situaciones impredecibles."});
palabra.push({key: "_FOOTER_INFO_TITULO", value: "Todos los derechos reservados Fabio H. Mancipe ©."});
palabra.push({key: "_FOOTER_INFO_CONTENIDO", value: "Fancipe, tu brújula en el mundo del aprendizaje, explora el Conocimiento en todas sus formas; Conocimiento trivial que nunca pensaste que necesitarías; Conocimientos Imprescindibles... para Situaciones Impredecibles."});
palabra.push({key: "_FOOTER_INFO_QUIENES_SOMOS", value: "Quienes somos"});
palabra.push({key: "_FOOTER_INFO_COOKIES", value: "Cookies, términos y condiciones de uso"});
palabra.push({key: "_FOOTER_INFO_HABEAS_DATA", value: "Política de privacidad y Habeas Data"});
palabra.push({key: "_FOOTER_INFO_WEB", value: "Web+"});
palabra.push({key: "_FOOTER_INFO_MAIL", value: "Mail+"});
palabra.push({key: "_FOOTER_CONTACTO_TITULO", value: "CONTACTO"});
palabra.push({key: "_FOOTER_CONTACTO_PAIS", value: "Colombia, SurAmérica"});
palabra.push({key: "_FOOTER_CONTACTO_ZONA", value: "Mosquera, Cundinamarca"});
palabra.push({key: "_FOOTER_CONTACTO_MAIL", value: "info@fancipe.com"});
palabra.push({key: "_FOOTER_CONTACTO_TEL", value: "(57)+(601)+3013558565"});
palabra.push({key: "_DATATABLES_DECIMAL", value: "."});
palabra.push({key: "_DATATABLES_EMPTYTABLE", value: "Tabla vacía"});
palabra.push({key: "_DATATABLES_INFO", value: "Mostrando _START_ a _END_ de _TOTAL_ entradas"});
palabra.push({key: "_DATATABLES_INFOEMPTY", value: "Sin información"});
palabra.push({key: "_DATATABLES_INFOFILTERED", value: "Filtrado de _MAX_ entradas totales"});
palabra.push({key: "_DATATABLES_THOUSANDS", value: ","});
palabra.push({key: "_DATATABLES_LENGTHMENU", value: "Ver _MENU_ Entradas"});
palabra.push({key: "_DATATABLES_LOADINGRECORDS", value: "Cargando…"});
palabra.push({key: "_DATATABLES_PROCESSING", value: "Procesando..."});
palabra.push({key: "_DATATABLES_SEARCH", value: "Buscar:"});
palabra.push({key: "_DATATABLES_ZERORECORDS", value: "No se encontró información"});
palabra.push({key: "_DATATABLES_PAGINATE_FIRST", value: "Primero"});
palabra.push({key: "_DATATABLES_PAGINATE_LAST", value: "Último"});
palabra.push({key: "_DATATABLES_PAGINATE_NEXT", value: "Siguiente"});
palabra.push({key: "_DATATABLES_PAGINATE_PREVIOUS", value: "Anterior"});
palabra.push({key: "_DATATABLES_ARIA_SORT_ASCENDING", value: ": Activar para ordenar ascendente"});
palabra.push({key: "_DATATABLES_ARIA_SORT_DESCENDING", value: ": Activar para ordenar descendente"});
palabra.push({key: "_COOKIES_MODAL_TITULO", value: "Aceptación de cookies"});
palabra.push({key: "_COOKIES_MODAL", value: "Para continuar con la navegación de nuestro sitio, debes acepar el uso de cookies"});
palabra.push({key: "_COOKIES_MODAL_RECHAZAR", value: "Lamentablemente no puedes continuar con la navegación en nuestro sitio; Por favor, cierra esta pestaña manualmente en tu navegador."});
palabra.push({key: "_ERR404_MSJ1", value: "La página solicitada no está en servidor"});
palabra.push({key: "_ERR404_MSJ2", value: "Si alguien tiene cien ovejas y pierde una, ¿no deja acaso las noventa y nueve en el campo y va a buscar la que se había perdido, hasta encontrarla? Y cuando la encuentra, la carga sobre sus hombros, lleno de alegría, y al llegar a su casa llama a sus amigos y vecinos, y les dice: Alégrense conmigo, porque encontré la oveja que se me había perdido. Les aseguro que, de la misma manera, habrá más alegría en el cielo por un solo pecador que se convierta, que por noventa y nueve justos que no necesitan convertirse."});
palabra.push({key: "_MENU_SALUD", value: "Salud"});
palabra.push({key: "_MENU_FINANZAS", value: "Finanzas Personales"});
palabra.push({key: "_MENU_INGENIERIA", value: "Ingenieria"});
palabra.push({key: "_MENU_ESTADISTICAS", value: "Estadisticas"});
palabra.push({key: "_MENU_UTILIDADES", value: "Utilidades"});
palabra.push({key: "_INICIO", value: "Inicio"});
palabra.push({key: "_INICIO_DESCR", value: "Bienvenidos a Fancipe"});
palabra.push({key: "_GRUPO", value: "Grupo"});
palabra.push({key: "_TITULO", value: "Título"});
palabra.push({key: "_TITULO_DESCR", value: "Descripción"});
palabra.push({key: "_CONSULTA_DIVIPOLA", value: "Consultas DIVIPOLA"});
palabra.push({key: "_CONSULTA_DIVIPOLA_DESCR", value: "Consultas de la división politico-administrativa de Colombia - DIVIPOLA, Herramienta de consulta de códigos de departamentos y códigos de municipios oficiales del gobierno de Colombia, generados y actualizados por el DANE"});
palabra.push({key: "_CONSULTA_DIVIPOLA_EJEMPLO", value: "ejemplo: Cundinamarca"});
palabra.push({key: "_CONSULTA_CIUO", value: "Consultas CIUO"});
palabra.push({key: "_CONSULTA_CIUO_DESCR", value: "Consultas de la Clasificación Internacional Uniforme de Ocupaciones CIUO según DANE y adaptada para Colombia"});
palabra.push({key: "_CONSULTA_CIUO_EJEMPLO", value: "ejemplo: Auxiliar"});
palabra.push({key: "_CONSULTA_ISS2001", value: "Consultas acuerdo 256 de 2001 - ISS2001"});
palabra.push({key: "_CONSULTA_ISS2001_DESCR", value: "Consultas de manual tarifario en salud del Instituto de Seguros Sociales ISS - acuerdo 256 de 2001 (ISS2001); Advertencia: El acuerdo 256 de 2001 NO tiene validez legal en el sistema general de seguridad social en salud en Colombia (SGSSS); Su importancia radica en cuanto a la modalidad de liquidación de los servicios de contratación del mercado privado, entre pagadores y prestadores."});
palabra.push({key: "_CONSULTA_ISS2001_EJEMPLO", value: "ejemplo: Consulta"});
palabra.push({key: "_CONSULTA_CUPS_R2238_2020", value: "Consultas CUPS R2238 de 2020 y R126 de 2021"});
palabra.push({key: "_CONSULTA_CUPS_R2238_2020_DESCR", value: "Vigencia 01/01/2021 a 31/12/2021; Corresponde a un ordenamiento detallado de los servicios en salud (Consultas, laboratorios, terapias, procedimientos ambulatorios y hospitalarios, exámenes especializados entre otros), que se realizan en la república de Colombia, en cumplimiento de los principios de interoperabilidad y estandarización de datos, utilizando para la identificación de un código y una descripción validada por el comité de expertos del país, independientemente de la profesión o disciplina del sector salud que los realice, así como del ámbito de realización de estos; es importante anotar que los servicios descritos NO forman parte de la cobertura de aseguramiento en salud, es decir, un servicio puede o NO puede estar cubierto por el sistema de salud."});
palabra.push({key: "_CONSULTA_CUPS_R2238_2020_EJEMPLO", value: "ejemplo: Consulta"});
palabra.push({key: "_CONSULTA_CUPS_R2077_2021", value: "Consultas CUPS R2077 de 2021"});
palabra.push({key: "_CONSULTA_CUPS_R2077_2021_DESCR", value: "Vigencia 01/01/2022 a 31/12/2022; Corresponde a un ordenamiento detallado de los servicios en salud (Consultas, laboratorios, terapias, procedimientos ambulatorios y hospitalarios, exámenes especializados entre otros), que se realizan en la república de Colombia, en cumplimiento de los principios de interoperabilidad y estandarización de datos, utilizando para la identificación de un código y una descripción validada por el comité de expertos del país, independientemente de la profesión o disciplina del sector salud que los realice, así como del ámbito de realización de estos; es importante anotar que los servicios descritos NO forman parte de la cobertura de aseguramiento en salud, es decir, un servicio puede o NO puede estar cubierto por el sistema de salud."});
palabra.push({key: "_CONSULTA_CUPS_R2077_2021_EJEMPLO", value: "ejemplo: Consulta"});
palabra.push({key: "_CONSULTA_CUPS_R2775_2022", value: "Consultas CUPS R2775 de 2022"});
palabra.push({key: "_CONSULTA_CUPS_R2775_2022_DESCR", value: "Vigencia a partir del 01/01/2023; Corresponde a un ordenamiento detallado de los servicios en salud (Consultas, laboratorios, terapias, procedimientos ambulatorios y hospitalarios, exámenes especializados entre otros), que se realizan en la república de Colombia, en cumplimiento de los principios de interoperabilidad y estandarización de datos, utilizando para la identificación de un código y una descripción validada por el comité de expertos del país, independientemente de la profesión o disciplina del sector salud que los realice, así como del ámbito de realización de estos; es importante anotar que los servicios descritos NO forman parte de la cobertura de aseguramiento en salud, es decir, un servicio puede o NO puede estar cubierto por el sistema de salud."});
palabra.push({key: "_CONSULTA_CUPS_R2775_2022_EJEMPLO", value: "ejemplo: Consulta"});
palabra.push({key: "_CONSULTA_CUPS_R2336_2023", value: "Consultas CUPS R2336 de 2023"});
palabra.push({key: "_CONSULTA_CUPS_R2336_2023_DESCR", value: "Vigencia a partir del 01/01/2024; Corresponde a un ordenamiento detallado de los servicios en salud (Consultas, laboratorios, terapias, procedimientos ambulatorios y hospitalarios, exámenes especializados entre otros), que se realizan en la república de Colombia, en cumplimiento de los principios de interoperabilidad y estandarización de datos, utilizando para la identificación de un código y una descripción validada por el comité de expertos del país, independientemente de la profesión o disciplina del sector salud que los realice, así como del ámbito de realización de estos; es importante anotar que los servicios descritos NO forman parte de la cobertura de aseguramiento en salud, es decir, un servicio puede o NO puede estar cubierto por el sistema de salud."});
palabra.push({key: "_CONSULTA_CUPS_R2336_2023_EJEMPLO", value: "ejemplo: Consulta"});
palabra.push({key: "_LIQUIDADOR_COPAGOS", value: "Liquidador de copagos"});
palabra.push({key: "_LIQUIDADOR_COPAGOS_DESCR", value: "El ministerio de la salud de la República de Colombia, establece el régimen de pagos compartidos y cuotas moderadoras en el sistema de salud; A continuación nos permitimos compartir un liquidador de copagos, siguiendo las pautas correspondientes establecidas por el citado ministerio."});
palabra.push({key: "_CRONOMETRO", value: "Cronómetro"});
palabra.push({key: "_CRONOMETRO_DESCR", value: "Nada más que decir, tan solo es un cronómetro."});
palabra.push({key: "_CRONOMETRO_EJEMPLO", value: "Mínimo 1"});
palabra.push({key: "_INP_BUSCADOR", value: "Buscador"});
palabra.push({key: "_INP_REGIMEN", value: "Régimen"});
palabra.push({key: "_INP_CLASE_AFILIADO", value: "Clase afiliado"});
palabra.push({key: "_INP_RANGO_NIVEL", value: "Rango/Nivel"});
palabra.push({key: "_INP_VALOR", value: "Valor"});
palabra.push({key: "_SELECT_AÑO", value: "Año"});
palabra.push({key: "_INP_MINUTOS", value: "Minutos"});
palabra.push({key: "_BTN_BUSCAR", value: "Buscar"});
palabra.push({key: "_BTN_ENVIAR", value: "Enviar"});
palabra.push({key: "_BTN_VER", value: "Ver"});
palabra.push({key: "_BTN_CERRAR", value: "Cerrar"});
palabra.push({key: "_BTN_CALCULAR", value: "Calcular"});
palabra.push({key: "_BTN_INICIAR", value: "Iniciar"});
palabra.push({key: "_REGISTRA_VALOR_BUSCAR", value: "Registra valor a buscar"});
palabra.push({key: "_SELECCIONA_AÑO", value: "Selecciona el año deseado"});
palabra.push({key: "_REGISTRA_VALOR_CALCULAR", value: "Registra valor a calcular"});
palabra.push({key: "_NUMERO_ENTERO_DECIMAL", value: "Registra número entero o decimal separado por punto"});
palabra.push({key: "_REGISTRA_NUM_MINUTOS", value: "Registra número de minutos"});
palabra.push({key: "_REFERENCIAS", value: "Referencias"});
palabra.push({key: "_REGISTRA", value: "Registra"});
palabra.push({key: "_SELECCIONA", value: "Selecciona"});
palabra.push({key: "_ES_REQUERIDO", value: "es requerido"});
palabra.push({key: "_INFO", value: "Información"});
palabra.push({key: "_ACEPTAR", value: "Aceptar"});
palabra.push({key: "_RECHAZAR", value: "Rechazar"});
palabra.push({key: "_AYUDA", value: "Ayuda"});
palabra.push({key: "_SIN_COINCIDENCIAS_ENCONTRADAS", value: "Sin coincidencias encontradas"});
palabra.push({key: "_INFO_COP_COTIZANTES", value: "Afiliados cotizantes NO pagan copago; el valor corresponde a cuota moderadora"});
palabra.push({key: "_INFO_COP_TOPE", value: "El valor liquidado ha superado el tope estipulado por evento, por lo tanto el valor copago es el tope máximo por evento"});
palabra.push({key: "_RESULTADO", value: "Resultado"});
palabra.push({key: "_VLR_COP", value: "Valor copago"});
palabra.push({key: "_VLR_LIQUIDACION", value: "Valor base de liquidación"});
palabra.push({key: "_HORAS", value: "Horas"});
palabra.push({key: "_MINUTOS", value: "Minutos"});
palabra.push({key: "_SEGUNDOS", value: "Segundos"});
palabra.push({key: "_MILISEGUNDOS", value: "Milisegundos"});

export { palabra };
