import React from 'react';


function Copagos(props) {

  
  return (
    <>
    <section className='caja7-block info'>

      <h4>Acerca de los copagos en salud</h4>

      <p>Los Copagos y Cuotas Moderadoras corresponden a pagos que deben hacer los afiliados del Sistema de Seguridad Social en salud en Colombia (SGSSS); de conformidad con el acuerdo 260 de 2004, el objetivo de las cuotas moderadoras es regular la utilización del servicio de salud y estimular su buen uso, y el objetivo de los copagos es ayudar a financiar el sistema de salud; si bien al final de cuentas se tratan de pagos que deben hacer los afiliados cuando requieran utilizar un servicio de salud, observamos que el espíritu de su existencia es diferente.</p>

      <p>De conformidad con el acuerdo 260 de 2004, los pagos deben realizarlo los afiliados teniendo en cuenta la siguiente característica:</p>

      <p>
        <blockquote>

        <cite>Acuerdo 260 de 2004</cite>
        <p></p>
        <cite>Artículo 3º. Aplicación de las cuotas moderadoras y copagos. Las cuotas moderadoras serán aplicables a los afiliados cotizantes y a sus beneficiarios, mientras que los copagos se aplicarán única y exclusivamente a los afiliados beneficiarios.</cite>

        <p></p>
        <cite>Parágrafo. De conformidad con el numeral tercero del artículo 160 de la Ley 100 de 1993, es deber del afiliado cotizante y de los beneficiarios cancelar las cuotas moderadoras y los copagos correspondientes....
        </cite>
        <p></p>
        <cite>Artículo 6º - Parágrafo 3º. Las cuotas moderadoras se pagarán al momento de utilización de cada uno de los servicios, en forma independiente</cite>
        </blockquote>
      </p>

      <h4>Excepciones en el pago</h4>


      <p>
        <blockquote>

        <cite>Acuerdo 260 de 2004</cite>
        <p></p>
        <cite>
        Artículo 6º - Parágrafo 2º. Si el usuario está inscrito o se somete a las prescripciones regulares de un programa especial de atención integral para patologías específicas, en el cual dicho usuario debe seguir un plan rutinario de actividades de control, no habrá lugar a cobro de cuotas moderadoras en dichos servicios.
        </cite>
        </blockquote>
      </p>


      <p>Se aprecia en parágrafo 2 una excepción frente al pago a pacientes inscritos a programas de patologías específicas, igualmente es importante precisar que existen más excepciones en el acuerdo 260 que eximen del pago de copagos y cuotas moderadoras, de conformidad con su artículo 7:</p>


      <p>
        <blockquote>

        <cite>Acuerdo 260 de 2004</cite>
        <p></p>
        <cite>
        Artículo 7º. Servicios sujetos al cobro de copagos. Deberán aplicarse copagos a todos los servicios contenidos en el plan obligatorio de salud, con excepción de:
        </cite>
        <p></p>
        <cite>
          <li>Servicios de promoción y prevención.</li>
          <li>Programas de control en atención materno infantil.</li>
          <li>Programas de control en atención de las enfermedades transmisibles.</li>
          <li>Enfermedades catastróficas o de alto costo.</li>
          <li>La atención inicial de urgencias.</li>
          <li>Los servicios enunciados en el artículo precedente.</li>
        </cite>

        </blockquote>
      </p>



      <p>También es importante precisar que existen excepciones adicionales, de conformidad a lo citado en la circular 016 de 2014 del ministerio de salud:</p>


      <p>
        <blockquote>

        <cite>Circular 016 de 2014</cite>
        <p></p>
        <cite>
        1. Las personas con discapacidad mental que tienen derecho a los servicios de salud de manera gratuita, a menos que su patrimonio directo o derivado de la prestación alimentaria, le permita asumir tales gastos (Ley 1306 de 2009[3], artículo 12).
        </cite>
        <p></p>
        <cite>
        2. Los beneficiarios de la Ley 1388 de 2010[4] (artículo 4o, parágrafo 2o), que de conformidad con lo previsto en su artículo 2o, corresponden a:
        </cite>
        <p></p>
        <cite>
          <li>La población menor de 18 años a quien se le haya confirmado, a través de los estudios pertinentes, el diagnóstico de cáncer en cualquiera de sus etapas, tipos o modalidades, certificado por el Onco-Hematólogo Pediátrico, debidamente acreditado para el ejercicio de su profesión, de acuerdo con la normatividad vigente.</li>
          <li>La población menor de 18 años con diagnóstico confirmado y certificado por el Onco-Hematólogo Pediátrico de Aplasias Medulares y Síndromes de Falla Medular, Desórdenes Hemorrágicos Hereditarios, Enfermedades Hematológicas Congénitas, Histiocitosis y Desórdenes Histiocitarios.</li>
          <li>La población menor de 18 años, cuando el médico general o cualquier especialista de la medicina, tenga sospecha de cáncer o de las enfermedades enunciadas en el literal anterior y se requieran exámenes y procedimientos especializados, hasta tanto el diagnóstico no se descarte.</li>
        </cite>
        <p></p>
        <cite>
          3. Las personas mayores de edad, en relación con la práctica de la vasectomía o ligadura de trompas (Ley 1412 de 2010[5], artículos 2o y 3o).
        </cite>
        <p></p>
        <cite>
        4. Los niños, niñas y adolescentes de Sisbén 1 y 2, con discapacidades físicas, sensoriales y cognitivas, enfermedades catastróficas y ruinosas que sean certificadas por el médico tratante, respecto a los servicios y medicamentos de la parte especial y diferenciada del Plan de Beneficios (Ley 1438 de 2011[6], artículo 18).
        </cite>
        <p></p>
        <cite>
        5. Los niños, niñas y adolescentes víctimas de violencia física o sexual y todas las formas de maltrato, que estén certificados por la autoridad competente, respecto de los servicios para su rehabilitación física, mental y atención integral hasta que se certifique médicamente su recuperación (Ley 1438 de 2011, artículo 19).
        </cite>
        <p></p>
        <cite>
        6. Todas las mujeres víctimas de violencia física o sexual, que estén certificadas por la autoridad competente, respecto de la prestación de los servicios de salud física, mental y atención integral, sin importar su régimen de afiliación, hasta que se certifique médicamente la recuperación de las víctimas (Ley 1438 de 2011, artículo 54).
        </cite>
        <p></p>
        <cite>
        7. Las víctimas del conflicto armado interno en los términos del artículo 3o de la Ley 1448 de 2011[7], y las pertenecientes a comunidades negras, afrocolombianas, raizales y palenqueras, en los términos del artículo 3o del Decreto-ley número 4635 de 2011, que se encuentren registradas en el Sisbén 1 y 2, en todo tipo de atención en salud que requieran. En caso de no hallarse afiliadas a ningún régimen, tendrán que ser afiliadas en forma inmediata al régimen subsidiado (Ley 1448 de 2011, artículo 52, parágrafo 2o; Decreto-ley 4635 de 2011[8] artículo 53, parágrafo 2o).
        </cite>
        <p></p>
        <cite>
        8. Las personas con cualquier tipo de discapacidad, en relación con su rehabilitación funcional[9], cuando se haya establecido el procedimiento requerido, en concordancia con los artículos 65 y 66 de la Ley 1438 de 2011 (Ley 1618 de 2013[10], artículo 9o, numeral 9).
        </cite>
        <p></p>
        <cite>
        9. Las víctimas de lesiones personales causadas por el uso de cualquier tipo de ácidos o sustancia similar o corrosiva que generen daño o destrucción al entrar en contacto con el tejido humano y generen algún tipo de deformidad o disfuncionalidad, respecto de los servicios, tratamientos médicos y psicológicos, procedimientos e intervenciones necesarias para restituir la fisionomía y funcionalidad de las zonas afectadas (Ley 1438 de 2011, artículo 53A, adicionado por el artículo 5o de la Ley 1639 de 2013)[11].
        </cite>
        </blockquote>
      </p>


      <p>Existen excepciones adicionales aplicables en el régimen subsidiado de conformidad según el acuerdo 365 de 2007:</p>


      <p>
        <blockquote>

        <cite>Acuerdo 365 de 2007 - Articulo 1</cite>
        <p></p>
        <cite>
        No serán objeto del cobro de copagos las poblaciones especiales que se identifiquen mediante instrumentos diferentes al SISBEN, tales como listados censales u otros utilizados para su identificación por parte de las entidades responsables de las poblaciones de que trata el artículo 4º del Acuerdo 244, adicionado por el artículo 1º del Acuerdo 273, siempre y cuando presenten condiciones de pobreza similares a las del nivel I del SISBEN.
        </cite>
        <p></p>
        <cite>
        1. Niños durante el primer año de vida
        </cite>
        <p></p>
        <cite>
          2. Población con clasificación UNO mediante encuesta SISBEN (cualquier edad)
        </cite>
        <p></p>
        <cite>
        3. Poblaciones especiales que se identifiquen mediante instrumentos diferentes al SISBEN, tales como listados censales u otros, siempre y cuando presenten condiciones de pobreza similares a las del nivel UNO del SISBEN tales como:
        </cite>
        <p></p>
        <cite>
          <li>Población infantil abandonada mayor de un año</li>
          <li>Población indigente</li>
          <li>Población en condiciones de desplazamiento forzado</li>
          <li>Población indígena</li>
          <li>Población desmovilizada</li>
          <li>Personas de la tercera edad en protección de ancianatos en instituciones de asistencia social</li>
          <li>Población rural migratoria</li>
          <li>Población ROM</li>
        </cite>
        <p></p>

        <cite>
        4. El núcleo familiar de la población desmovilizada una vez identificado mediante la encuesta SISBEN, no será sujeto del cobro de copagos siempre y cuando se clasifique en el nivel UNO del SISBEN.
        </cite>
        <p></p>
        <cite>
        El núcleo familiar de la población desmovilizada una vez identificado mediante la encuesta SISBEN, no será sujeto del cobro de copagos, siempre y cuando se identifique en el nivel I del SISBEN. Para el cobro de copagos a las personas identificadas en el nivel II del SISBEN, se aplicará lo establecido en el numeral 3º del artículo 11 del Acuerdo 260 del Consejo Nacional de Seguridad Social en Salud
        </cite>
        <p></p>
        <cite>
        PARÁGRAFO.- Respecto de la población desplazada o desmovilizada que haya sido identificada mediante encuesta SISBEN en sus municipios de origen, prevalece su identificación como desplazado o desmovilizado en el lugar de residencia actual y por tanto no estará sujeta al cobro de copagos.
        </cite>
        </blockquote>
      </p>

      <h4>En resumen</h4>

      <p>Las cuotas moderadoras son aplicables a los afiliados cotizantes y a sus beneficiarios, mientras que los copagos se aplicarán única y exclusivamente a los afiliados beneficiarios.</p>

      <p>Para beneficiarios: Los servicios que NO se encuentran caracterizados como pago de cuota moderadora, serán objeto de liquidación de copago.</p>

      <p>Para cotizantes: todos los servicios se encuentran caracterizados como pago de cuota moderadora (copago es solo para beneficiarios).</p>

      <p>En el régimen subsidiado no se aplica cuotas moderadoras, así mismo no se aplica recaudo en consulta médica, odontológica y consulta por otras disciplinas no médicas, exámenes de laboratorio, imagenología, despacho de medicamentos cubiertos en el Plan de Beneficios en Salud financiado con los recursos de la UPC del régimen subsidiado y consulta de urgencia.</p>

      <p>Para los demás servicios en régimen subsidiado aplican copago, eso sí, teniendo en cuenta las excepciones ya mencionadas.</p>

    </section>
    </>
  );
}

export { Copagos };



