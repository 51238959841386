import React from 'react';

import { useIdioma } from '../../context/IdiomaProvider';
import { Link } from 'react-router-dom';

import { AnuncioHorizontal } from '../../components/GoogleAdSense/GoogleAdSense';

function Footer(props) {

  const { vlrWord } = useIdioma();


  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' //desplazamiento suave
    });
  }


  return (
    <>
    <section className="footer">
      <section className="footer__info">
        <h2>{vlrWord('_FOOTER_INFO_TITULO')}</h2>
        <p>{vlrWord('_FOOTER_INFO_CONTENIDO')}</p>
        <section>
          <Link to='/quienes-somos' onClick={scrollToSection} >{vlrWord('_FOOTER_INFO_QUIENES_SOMOS')}</Link>
          <Link to='/cookies' onClick={scrollToSection}>{vlrWord('_FOOTER_INFO_COOKIES')}</Link>
          <Link to='/habeas-data' onClick={scrollToSection}>{vlrWord('_FOOTER_INFO_HABEAS_DATA')}</Link>
          <Link to='https://www.fancipe.com:2083/' target='web'>{vlrWord('_FOOTER_INFO_WEB')}</Link>
          <Link to='https://www.fancipe.com:2096/' target='mail'>{vlrWord('_FOOTER_INFO_MAIL')}</Link>
        </section>
      </section>

      <section className="footer__contacto">
        <h2>{vlrWord('_FOOTER_CONTACTO_TITULO')}</h2>
        <section className="contacto--ico"><span className="south_americasvg svg-color-claro-forced"></span><p>{vlrWord('_FOOTER_CONTACTO_PAIS')}</p></section>
        <section className="contacto--ico"><span className="location_onsvg svg-color-claro-forced"></span><p>{vlrWord('_FOOTER_CONTACTO_ZONA')}</p></section>
        <section className="contacto--ico"><span className="mailsvg svg-color-claro-forced"></span><p>{vlrWord('_FOOTER_CONTACTO_MAIL')}</p></section>
      </section>

    </section>

    </>
  );
}

export { Footer };

