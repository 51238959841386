import React from 'react';

import { Home } from './pages/Home';

import { ConsultaDivipola } from './pages/all/Estadisticas_consulta_divipola/ConsultaDivipola';
import { ConsultaCIUO } from './pages/all/Estadisticas_consulta_CIUO/ConsultaCIUO';
import { ConsultaISS2001 } from './pages/all/Salud_consulta_ISS2001/ConsultaISS2001';
import { ConsultaCUPSR2238_2020 } from './pages/all/Salud_consulta_cups_R2238_2020/ConsultaCUPSR2238_2020';
import { ConsultaCUPSR2077_2021 } from './pages/all/Salud_consulta_cups_R2077_2021/ConsultaCUPSR2077_2021';
import { ConsultaCUPSR2775_2022 } from './pages/all/Salud_consulta_cups_R2775_2022/ConsultaCUPSR2775_2022';
import { ConsultaCUPSR2336_2023 } from './pages/all/Salud_consulta_cups_R2336_2023/ConsultaCUPSR2336_2023';
import { LiquidadorCopagos } from './pages/all/Salud_liquidador_copagos/LiquidadorCopagos';
import { Cronometro } from './pages/all/Utilidades_cronometro/Cronometro';



/*
descripcion de propiedades
path_to = utilizado para Route, Link o NavLink
element = componente asocialdo la cual debe ser usado en Route
class = en caso de requerirse, para ser usado en NavLink
titulo = Texto de nombre de menu
tituloDescr = Texto de nombre de menu
grupo, subgrupo, categoria = categorizacion del componente
actualizado = fecha de actualizacion en formato dd/mm/yyy
autor = Fabio H. Mancipe
urlFile1 = ruta de publicacion de insumo archivo1
*/

//voy en consecutivo 1008

const rutasNav = [];

rutasNav.push({
  path_to: '/',
  element: <Home />,
  titulo: '_INICIO',
  tituloDescr: '_INICIO_DESCR',
});






rutasNav.push({
  path_to: '/Utilidades/1009-cronometro',
  element: <Cronometro />,
  titulo: '_CRONOMETRO',
  tituloDescr: '_CRONOMETRO_DESCR',
  grupo: '_MENU_UTILIDADES',
  actualizado: '18/08/2024',
  autor: 'Fabio H. Mancipe',
  //urlFile1: '/assets/files/Salud_liquidador_copagos/base_liquidacion.xlsx',
});




rutasNav.push({
  path_to: '/Salud/1007-copagos-salud',
  element: <LiquidadorCopagos />,
  titulo: '_LIQUIDADOR_COPAGOS',
  tituloDescr: '_LIQUIDADOR_COPAGOS_DESCR',
  grupo: '_MENU_SALUD',
  actualizado: '04/11/2023',
  autor: 'Fabio H. Mancipe',
  urlFile1: '/assets/files/Salud_liquidador_copagos/base_liquidacion.xlsx',
});





rutasNav.push({
  path_to: '/Salud/1008-cups-r2336-2023',
  element: <ConsultaCUPSR2336_2023 />,
  titulo: '_CONSULTA_CUPS_R2336_2023',
  tituloDescr: '_CONSULTA_CUPS_R2336_2023_DESCR',
  grupo: '_MENU_SALUD',
  actualizado: '13/03/2024',
  autor: 'Fabio H. Mancipe',
  urlFile1: '/assets/files/Salud_consulta_cups_R2336_2023/R2336_2023.xlsx',
});




rutasNav.push({
  path_to: '/Salud/1004-cups-r2775-2022',
  element: <ConsultaCUPSR2775_2022 />,
  titulo: '_CONSULTA_CUPS_R2775_2022',
  tituloDescr: '_CONSULTA_CUPS_R2775_2022_DESCR',
  grupo: '_MENU_SALUD',
  actualizado: '21/10/2023',
  autor: 'Fabio H. Mancipe',
  urlFile1: '/assets/files/Salud_consulta_cups_R2775_2022/R2775_2022.xlsx',
});



rutasNav.push({
  path_to: '/Salud/1006-cups-r2077-2021',
  element: <ConsultaCUPSR2077_2021 />,
  titulo: '_CONSULTA_CUPS_R2077_2021',
  tituloDescr: '_CONSULTA_CUPS_R2077_2021_DESCR',
  grupo: '_MENU_SALUD',
  actualizado: '21/10/2023',
  autor: 'Fabio H. Mancipe',
  urlFile1: '/assets/files/Salud_consulta_cups_R2238_2020/R2238_2020.xlsx',
});



rutasNav.push({
  path_to: '/Salud/1005-cups-r2238-2020',
  element: <ConsultaCUPSR2238_2020 />,
  titulo: '_CONSULTA_CUPS_R2238_2020',
  tituloDescr: '_CONSULTA_CUPS_R2238_2020_DESCR',
  grupo: '_MENU_SALUD',
  actualizado: '21/10/2023',
  autor: 'Fabio H. Mancipe',
  urlFile1: '/assets/files/Salud_consulta_cups_R2238_2020/R2238_2020.xlsx',
});



rutasNav.push({
  path_to: '/Salud/1003-iss2001-acuerdo256_2001',
  element: <ConsultaISS2001 />,
  titulo: '_CONSULTA_ISS2001',
  tituloDescr: '_CONSULTA_ISS2001_DESCR',
  grupo: '_MENU_SALUD',
  actualizado: '01/10/2023',
  autor: 'Fabio H. Mancipe',
  urlFile1: '/assets/files/Salud_consulta_ISS2001/iss2001.xlsx',
});




//https://www.dane.gov.co/index.php/sistema-estadistico-nacional-sen/normas-y-estandares/nomenclaturas-y-clasificaciones/clasificaciones/clasificacion-internacional-uniforme-de-ocupaciones-ciuo
rutasNav.push({
  path_to: '/Estadisticas/1002-consulta-CIUO-adaptada-colombia',
  element: <ConsultaCIUO />,
  titulo: '_CONSULTA_CIUO',
  tituloDescr: '_CONSULTA_CIUO_DESCR',
  grupo: '_MENU_ESTADISTICAS',
  actualizado: '01/10/2023',
  autor: 'Fabio H. Mancipe',
  urlFile1: '/assets/files/Estadisticas_consulta_CIUO/INDICE_CIUO08AC_Abril2017.xls',
});



//https://www.datos.gov.co/Mapas-Nacionales/DIVIPOLA-C-digos-municipios/gdxc-w37w
rutasNav.push({
  path_to: '/Estadisticas/1001-consulta-divipola',
  element: <ConsultaDivipola />,
  titulo: '_CONSULTA_DIVIPOLA',
  tituloDescr: '_CONSULTA_DIVIPOLA_DESCR',
  grupo: '_MENU_ESTADISTICAS',
  actualizado: '10/06/2020',
  autor: 'Fabio H. Mancipe',
  urlFile1: '/assets/files/Estadisticas_consulta_divipola/divipola.csv',
});





export { rutasNav };
