import React, { useState, cloneElement } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';

import { Err404 } from './Err404';
import { QuienesSomos, Cookies, HabeasData } from './all/Info/Info';

import { MenuNavegacion } from '../components/MenuNavegacion/MenuNavegacion';
import { Footer } from '../components/Footer/Footer';
import { ModalCookies } from '../components/Modal/ModalCookies';


import { IdiomaProvider, useIdioma } from '../context/IdiomaProvider';
import { ThemeProvider } from '../context/ThemeProvider';

import '../assets/css/maestro.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


//import { need_validation } from '../assets/bootstrap/js/bootstrap-otros.js';
//need_validation();



function App() {

  const [msjModal, setMsjModal] = useState([]);


  return (
    <>
    <IdiomaProvider>
    <ThemeProvider>
      <HashRouter>
        {/*ctrl cookies*/}
        <ModalCookies />
        {/*menu navegacion y header/logo */}
        <MenuNavegacion />
        {/*establecer las rutas de react-router-dom*/}
        <RutasURL />
        <Footer />
     </HashRouter>
     </ThemeProvider>
     </IdiomaProvider>
    </>
  );

}


function RutasURL (){

  const { menuNav } = useIdioma();
  if ( menuNav === null){ return; }

  return(
    <Routes>
      {menuNav.map((dato) => {
        const elementProps = cloneElement(dato.element, dato); //add props a elemento
        return ( <Route path={dato.path_to} element={elementProps} key={dato.path_to} /> );
      })}
      <Route path='quienes-somos' element={<QuienesSomos />} />
      <Route path='cookies' element={<Cookies />} />
      <Route path='habeas-data' element={<HabeasData />} />
      <Route path='*' element={<Err404 />} />
    </Routes>
  );

}


export { App };

