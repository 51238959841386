import React, { useEffect, useState } from 'react';

import { Head } from '../../../utils/Head';
import { Excel_to_JSON } from '../../../utils/ProcesarArchivos';
import { FormatoMoneda } from '../../../utils/Metodos';
import { Inp_select_año, Inp_regimen, Inp_clase_afiliado, Inp_rango_nivel, Inp_valor_moneda, Inp_btn_constructor } from '../../../utils/inputs';

import { ImgEspera } from '../../../components/ImgEspera/ImgEspera';
import { Referencias } from '../../../components/Referencias/Referencias';

import { Copagos } from './Copagos';

import { useIdioma } from '../../../context/IdiomaProvider';




function LiquidadorCopagos(props) {


  const { vlrWord } = useIdioma();


  const [imgEspera, setImgEspera] = useState(false);

  const [datos, setDatos] = useState([]);
  
  const [años, setAños] = useState([]);

  const [contributivo, setContributivo] = useState(false);

  const [resultado, setResultado] = useState('');
  const [notas_resultado, setNotas_resultado] = useState('');
  const [notas_resumen, setNotas_resumen] = useState('');


  

  useEffect(() => {
    const ExcelToJSON = async () => {

      setImgEspera(true);
      
      const json = await Excel_to_JSON(props.urlFile1, 0);

      json.map((dato)=>{

        const rev = años.filter((fdato) => {
          return fdato.value === dato.año;
        });

        if (rev.length === 0){
          let tmpAños = años;
          tmpAños.push({'value': dato.año, 'descr': dato.año});
          setAños(tmpAños);
        }
      });

      
      setDatos(json);
      setImgEspera(false);
    };

    ExcelToJSON();
  }, []);






  //establecer estados segun registro
  const sendForm = (event) => {

    event.preventDefault();

    let objForm = document.querySelector(".form-generico");

    objForm.classList.add('was-validated');
    
    if (!objForm.reportValidity()){
      return;
    }


    //calcular
    //calcular

    let t_año, t_regimen, t_vlr, t_claseafil, t_rango;

    t_año = document.querySelector('.Inp_select_año').value; 
    t_regimen = document.querySelector('input[name="Inp_regimen"]:checked').value; 
    t_vlr = document.querySelector('.Inp_valor_moneda').value;

    try {
      t_claseafil = document.querySelector('input[name="Inp_clase_afiliado"]:checked').value;
    } catch (error) {
      t_claseafil = '';
    }


    try {
      t_rango = document.querySelector('input[name="Inp_rango_nivel"]:checked').value;
    } catch (error) {
      t_rango = '';
    }

    //aprobar validacion y calculo
    try {
      if(t_regimen === 'contributivo' && t_año !== '' && t_claseafil !== '' && t_rango !== '' && Number(t_vlr) >= 0 ){

        validarContributivo (t_año, t_regimen, t_vlr, t_claseafil, t_rango);

      } else if (t_regimen === 'subsidiado' && t_año !== '' && Number(t_vlr) >= 0){

        validarSubsidiado (t_año, t_regimen, t_vlr, t_claseafil, t_rango);

      }

    } catch (error) {
      //
    }

  }; 


  



  const validarContributivo = (t_año, t_regimen, t_vlr, t_claseafil, t_rango) => {

    setNotas_resumen(`${vlrWord('_SELECT_AÑO')} ${t_año} ${vlrWord('_INP_REGIMEN')} ${t_regimen}, ${vlrWord('_INP_CLASE_AFILIADO')} ${t_claseafil} ${vlrWord('_INP_RANGO_NIVEL')} ${t_rango}, ${vlrWord('_VLR_LIQUIDACION')} ${FormatoMoneda(t_vlr)}`);

    datos.map((dato) => {

      if (dato.año === t_año && dato.nivel === t_rango){

        if(t_claseafil === 'cotizante'){
          setResultado(dato.CuotaModeradora);
          setNotas_resultado(vlrWord('_INFO_COP_COTIZANTES'));
        } else if (t_claseafil === 'beneficiario'){

          let tmp_liq = (Number(t_vlr) * Number(dato.PorcentajeCopago)) / 100;

          if (tmp_liq > Number(dato.TopeEvento)){
            setResultado(Number(dato.TopeEvento));
            setNotas_resultado(vlrWord('_INFO_COP_TOPE'));
          } else {
            setResultado(tmp_liq);
            setNotas_resultado('');
          }
        } else {
          setResultado('');
          setNotas_resultado('');
          setNotas_resumen('');
        }

      }

    });

  };




  const validarSubsidiado = (t_año, t_regimen, t_vlr) => {

    setNotas_resumen(`${vlrWord('_SELECT_AÑO')} ${t_año} ${vlrWord('_INP_REGIMEN')} ${t_regimen}, ${vlrWord('_VLR_LIQUIDACION')} ${FormatoMoneda(t_vlr)}`);


    datos.map((dato) => {

      if (dato.año === t_año && dato.nivel === 'S'){

          let tmp_liq = (Number(t_vlr) * Number(dato.PorcentajeCopago)) / 100;

          if (tmp_liq > Number(dato.TopeEvento)){
            setResultado(Number(dato.TopeEvento));
            setNotas_resultado(vlrWord('_INFO_COP_TOPE'));
          } else {
            setResultado(tmp_liq);
            setNotas_resultado('');
          }

      }

    });

  };

















  const verificarRegimen = () => {
    try {
      if (document.querySelector('input[name="Inp_regimen"]:checked').value === 'contributivo'){
        setContributivo(true);
      }
      else {
        setContributivo(false);
      }
    } catch (error) {
      setContributivo(false);
    }
  }
  


  
  return (
    <>
      <Head title={props.titulo} description={props.tituloDescr} />
      <section className='caja3-block'>
        <h3>{props.titulo}</h3>
        <p>[{props.grupo}]</p>
        <p>{props.tituloDescr}</p>
      </section>


      <ImgEspera ver={imgEspera} />


      <section className='caja2-block'>
        <form className='form-generico' >

        <Inp_select_año att_options={años} att_required={true} sect_msj_validation={true} />
        
        <section className='grid-col-span1' onClick={() => verificarRegimen()} >
        <Inp_regimen att_required={true} att_oculto={false} att_disabled={false} />
        </section>

        <Inp_clase_afiliado att_required={contributivo} att_disabled={!contributivo} />

        <Inp_rango_nivel att_required={contributivo} att_disabled={!contributivo} />

        <Inp_valor_moneda att_required={true} sect_msj_validation={true} att_comentarios={'_NUMERO_ENTERO_DECIMAL'} att_otros={{min: '0', max: '99999999999.99', step: "0.01"}} />


        <section className='flex-center-col grid-col-span2'>
          <Inp_btn_constructor 
          clss_btn={'btn btn-ico'}
          att_name={'Inp_btn_buscar'} 
          att_value={'_BTN_CALCULAR'} 
          att_title={'_BTN_CALCULAR'} 
          btn_modelo={2}
          att_style={'btn-secondary'}
          svg_ico={'calculatesvg'}
          svg_color={'svg-color-claro-forced'}
          text_color={'color-claro-forced'}
          btn_redondo={false}
          onClick={(event) => sendForm(event)} 
          />
        </section>

        </form>
      </section>

      {Number(resultado) != '' && 
        <section className='caja6-block alert alert-primary' role='alert' >
          <table className='table table-primary'>
            <thead>
              <tr>
              <th>{vlrWord('_VLR_COP')}</th>
              <th>{FormatoMoneda(resultado, 2)}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={2}>{notas_resumen}</td>
              </tr>

              {notas_resultado !== '' && 
              <tr>
                <td colSpan={2}>{notas_resultado}</td>
              </tr>
              }
            </tbody>
          </table>
          
        </section>
      }


      <Copagos />


      <Referencias cita='Ministerio de la Protección Social - Consejo Nacional de Seguridad Social en Salud. (04 de Febrero de 2004). Por el cual se define el régimen de pagos compartidos y cuotas moderadoras dentro del Sistema General de Seguridad Social en Salud. [Acuerdo 260 de 2004].'/>

      <Referencias cita='Ministerio de la Protección Social - Consejo Nacional de Seguridad Social en Salud. (20 de Septiembre de 2007). Por el cual se establecen disposiciones para el no cobro de copagos a poblaciones especiales en el régimen subsidiado. [Acuerdo 365 de 2007].'/>

      <Referencias cita='Ministerio De Salud y Protección Social. (22 de Marzo de 2014). Exención concurrente del pago de cuotas moderadoras y copagos por leyes especiales. [Circular 16 de 2014].'/>

    </>
  );
}

export { LiquidadorCopagos };



