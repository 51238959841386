import React from 'react';

function CUPS(props) {

  
  return (
    <>
      <section className='caja7-block info'>

      <h4>Acerca de los CUPS</h4>

      <p>Los CUPS corresponde a un ordenamiento detallado de los servicios en salud (Consultas, laboratorios, terapias, procedimientos ambulatorios y hospitalarios, exámenes especializados entre otros), que se realizan en la república de Colombia. </p>

      <p>Según la norma, la Clasificación Única de Procedimientos en Salud- CUPS. Es un ordenamiento lógico detallado de los procedimientos en salud que se realizan en el país, en cumplimiento de los principios de interoperabilidad y estandarización de datos utilizando para tal efecto la identificación por un código y una descripción validada por los expertos del país, independientemente de la profesión o disciplina del sector salud que los realice, así como del ámbito de realización de estos.</p>

      <h4>Estructura</h4>

      <p>El código cuenta con una estructura de nivel jerárquico; cada código cuenta como máximo seis caracteres, que permiten ubicarlo con exactitud según el nivel jerárquico, tanto en forma general como detallada de manera sistemática y concatenada.</p>

      <p>Los niveles jerárquicos son los siguientes:</p>

      <section>
      <table className='table'>
        <thead>
          <tr>
            <th>Grupo</th>
            <th>XX</th>
          </tr>
          <tr>
            <th>SubGrupo</th>
            <th>XX.X</th>
          </tr>
          <tr>
            <th>Categoria</th>
            <th>XX.X.X</th>
          </tr>
          <tr>
            <th>SubCategoria</th>
            <th>XX.X.X.XX</th>
          </tr>
        </thead>
      </table>
      </section>


      <p>Si tomamos, por ejemplo, el servicio de resección de nervio craneal:</p>

      <p>04.0.7.03 - ESCISIÓN [RESECCIÓN] DE NERVIO CRANEAL O PERIFÉRICO</p>

      <p>o lo que es lo mismo:</p>

      <p>040703 - ESCISIÓN [RESECCIÓN] DE NERVIO CRANEAL O PERIFÉRICO</p>

      <p>Se aprecia que el código 04.0.7.03 está conformado por 6 caracteres, y en donde ello revela su clasificación como procedimiento o como servicio de salud, la cual, para su correcta clasificación se interpreta de la siguiente forma:</p>
    

      <section>
      <table className='table'>
        <thead>
          <tr>
            <th>Grupo</th>
            <th>04</th>
          </tr>
          <tr>
            <th>SubGrupo</th>
            <th>04.0</th>
          </tr>
          <tr>
            <th>Categoria</th>
            <th>04.0.7</th>
          </tr>
          <tr>
            <th>SubCategoria</th>
            <th>04.0.7.03</th>
          </tr>
        </thead>
      </table>
      </section>




      <p>Si nos remitimos a la citada norma, podemos apreciar lo siguiente:</p>


      <section>
      <table className='table'>
        <thead>
          <tr>
            <th>Grupo</th>
            <th>04 = PROCEDIMIENTOS EN NERVIOS CRANEALES Y PERIFÉRICOS</th>
          </tr>
          <tr>
            <th>SubGrupo</th>
            <th>04.0 = INCISIÓN, DIVISION Y ESCISIÓN DE NERVIOS CRANEALES Y PERIFÉRICOS</th>
          </tr>
          <tr>
            <th>Categoria</th>
            <th>04.0.7 = ESCISION O RESECCIÓN DE NERVIOS CRANEALES O PERIFÉRICOS</th>
          </tr>
          <tr>
            <th>SubCategoria</th>
            <th>04.0.7.03 = ESCISIÓN [RESECCIÓN] DE NERVIO CRANEAL O PERIFÉRICO</th>
          </tr>
        </thead>
      </table>
      </section>



      <h4>Notas de instrucción</h4>

      <p>Es importante anotar que en la norma se encuentran notas de instrucción, que pueden verse como incluye, excluye y simultáneo; estas notas pueden estar a nivel de Grupo, Subgrupo, Categoría o Subcategoría, según sea el caso, es decir, si se encuentra en un subgrupo también se aplicará a sus correspondientes categorías y subcategorías. Cuando dentro de un mismo paréntesis van dos códigos ellos se unen con guion (-) para indicar continuidad. Cuando entre ellos hay códigos que no pertenecen a la nota de instrucción, se intercala una coma para indicar el salto.</p>

      <p>La norma nos aclara lo siguiente:</p>

      <p>Incluye: Esta anotación aparece inmediatamente debajo de un nivel jerárquico para definir más ampliamente o para dar ejemplos del contenido del nivel.</p>

      <p>Excluye: Esta anotación se aplica para referirse a los códigos que no deben tenerse en cuenta.</p>

      <p>Simultáneo: Esta instrucción se utiliza en la Lista Tabular con dos fines:</p>
      <p>

        <li>Como una instrucción para codificar un procedimiento que se puede realizar de manera independiente (sólo) o como componente de otro procedimiento cuando ellos se realizan al mismo tiempo.</li>

        <li>Como una introducción para codificar el uso simultáneo de equipos especiales correlacionados.</li>

      </p>



      <p>Ejemplo1:</p>

      <p>Subcategoría: 89.0.2.03 CONSULTA DE PRIMERA VEZ POR ODONTOLOGÍA GENERAL</p>

      <p>Incluye (se incluye en el servicio): ANAMNESIS, EXAMEN ORAL Y ELABORACIÓN DEL ODONTOGRAMA O CARTA ODONTOLÓGICA, PRESCRIPCIÓN FARMACOLÓGICA, ELABORACIÓN DE UN PLAN DE TRATAMIENTO, REMISIÓN AL ESPECIALISTA</p>

      <p>Excluye (no incluido en el servicio): PROCEDIMIENTOS QUIRÚRGICOS DE OPERATORIA, CIRUGÍA, ENDODONCIA, EXODONCIA (23.), PROFILAXIS Y DE APLICACIÓN DE SUSTANCIAS PREVENTIVAS (99. 7.)</p>


      <p>Ejemplo2:</p>

      <p>Categoría: 39.5.2. OTRA REPARACIÓN DE ANEURISMA</p>

      <p>Simultáneo: ECOGRAFIA DOPPLER COMO GUIA PARA PROCEDIMIENTOS (88.2.8.40)</p>

      <p>Simultaneo como instrucción para codificar un procedimiento que se puede realizar de manera independiente (sólo) o como componente de otro procedimiento cuando ellos se realizan al mismo tiempo.</p>


      <p>Ejemplo3:</p>

      <p>Grupo: 35.9. OTROS PROCEDIMIENTOS EN VÁLVULAS Y TABIQUES CARDÍACOS</p>

      <p>Simultáneo: CUANDO SE REALICE DERIVACIÓN CARDIOPULMONAR CIRCULACIÓN EXTRACORPOREA] (39 6 1.)</p>

      <p>Simultaneo como una introducción para codificar el uso simultáneo de equipos especiales correlacionados.</p>

      </section>

    </>
  );
}

export { CUPS };
