import React from 'react';



function ImgEspera(props) {
  return (
    <>
      {props.ver && <SeccionImagen />}
    </>
  );
}


function SeccionImagen (){
  return (
    <>
    <section className="cargando">
      <span className="carga--circ"></span>
      <span className="carga--circ"></span>
      <span className="carga--circ"></span>
      <span className="carga--circ"></span>
      <span className="carga--circ"></span>
      <span className="carga--circ"></span>
      <span className="carga--text"></span>
    </section>
    </>
  );

}

export { ImgEspera };
