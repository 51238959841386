import React from 'react';

import { useIdioma } from '../../context/IdiomaProvider';



function Referencias(props) {

  const { vlrWord } = useIdioma();

  return (
    <>
    <section className="caja-referencias">
      <p><strong>{vlrWord('_REFERENCIAS')}</strong></p>
      <cite>{props.cita}</cite>
    </section>
    </>
  );
}

export { Referencias };

