import React, { Component } from 'react';

import $ from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net';



class DataTables extends Component {

    //estadosAlmacenados
    constructor(props) {
        super(props);
        this.state = {
            tagsDataTables: [],
            clasesTable: '',
            clasestHead: '',
            clasestBody: '',
            datos: [],
            titulos: [],
        }
    }


    componentDidMount() {

        this.setState({
            titulos: this.props.titulos,
            datos: this.props.objDatos, 
            tagsDataTables: this.props.tagsDataTables,
            clasesTable: this.props.clasesTable,
            clasestHead: this.props.clasestHead,
            clasestBody: this.props.clasestBody,
        });

        const lang = this.props.tagsDataTables;

        //inicializar DataTables
        setTimeout(function(){
            $('.tblDataTables').DataTable({
                language: lang,
                destroy: true,
            });

        }, 100);

    }



    componentDidUpdate() {


        if ((this.props.objDatos !== this.state.datos) || (this.props.tagsDataTables !== this.state.tagsDataTables)){

            this.setState({
                ...this.state, 
                datos: this.props.objDatos, 
                tagsDataTables: this.props.tagsDataTables, 
            });

            //destruir DataTables
            $('.tblDataTables')
            .DataTable()
            .destroy();
        
            const lang = this.props.tagsDataTables;

            setTimeout(function(){
                $('.tblDataTables').DataTable({
                    language: lang,
                    destroy: true,
                });
            }, 100);

        }

    }


    


    render(){

        //para cuantificacion de grupos en seccion movil
        let arrGrupo = [];

        
        return (
            <>
            <div className='divDataTables'>
                <table className={`tblDataTables ${this.state.clasesTable}`}>
                <thead className={this.state.clasestHead}>
                    <tr>
                    {
                        Object.values(this.state.titulos).map((valor, indice) => (
                            <th key={indice}>{valor}</th>
                        ))
                    }
                    </tr>
                </thead>


                <tbody className={this.state.clasestBody}>
                {
                    this.state.datos.map((dato, indice) => {
                    return (
                        <tr key={indice}>
                        {
                            Object.values(dato).map((valor, indice2) => (
                                <td key={indice2}>{valor}</td>
                            ))
                        }
                        </tr>
                    )
                    })
                }
                    
                </tbody>
                </table>
            </div>


            {/*responsive para movil*/}
            <section className='sectionDataTables'>
            {
                this.state.datos.map((dato, indice) => {

                let grupo = Math.floor((indice)/10)+1;

                //preparar grupos para lista de navegacion
                if(arrGrupo.indexOf(grupo) < 0){
                    arrGrupo.push(grupo);
                }

                return (
                    <section key={indice} className='caja1-block page' data-page={grupo} >
                    {
                    Object.values(dato).map((valor, indice2) => (
                        <div key={indice2} className='secciondivDataTables'>
                        <section>{this.state.titulos[indice2]}</section>
                        <section>{valor}</section>
                        </div>
                    ))
                    }
                    </section>
                )
                })
            }




            <ul className='pagination'>
                <li className='page-item anterior'><a className='page-link'>&laquo;</a></li>
                {
                    arrGrupo.map((dato) => {
                    return(
                        <li key={dato} className='page-item numeracion' data-page={dato}><a className='page-link' >{dato}</a></li>
                    )
                })
                }
                <li className='page-item siguiente'><a className='page-link'>&raquo;</a></li>
            </ul>

            
            </section>
            <Pagination grupos={arrGrupo} />
            </>

        );

    }
}





function Pagination (props){

    $(document).ready(function() {
        let currentPage = 1;
      
        // Mostrar la primera página y ocultar las demás
        showPage(currentPage);
      
        // Manejar el clic en los elementos de paginación
        $(".pagination li.numeracion").on("click", function() {
          let clickedPage = $(this).data("page");

          if (clickedPage !== currentPage) {
            showPage(clickedPage);
            currentPage = clickedPage;
          }
        });
      
        // Manejar el clic en el botón "anterior"
        $(".anterior").on("click", function() {
          if (currentPage > 1) {
            showPage(currentPage - 1);
            currentPage--;
          }
        });
      
        // Manejar el clic en el botón "siguiente"
        $(".siguiente").on("click", function() {
          if (currentPage < props.grupos.length) {
            showPage(currentPage + 1);
            currentPage++;
          }
        });
      
        function showPage(pagina) {
            // Ocultar todas las páginas
            $(".page").hide();
            // Mostrar la página seleccionada
            $(".page[data-page='" + pagina + "']").show();
        
            // Actualizar el estado activo de la paginación
            $(".pagination li").removeClass("active");
            $(".pagination li[data-page='" + pagina + "']").addClass("active");
        


            // Desactivar el botón "anterior" en la primera página
            if (pagina === 1) {
            $(".anterior").addClass("disabled");
            } else {
            $(".anterior").removeClass("disabled");
            }
        
            // Desactivar el botón "siguiente" en la última página
            if (pagina === props.grupos.length) {
            $(".siguiente").addClass("disabled");
            } else {
            $(".siguiente").removeClass("disabled");
            }



            // ajustar vista de paginacion
            const mostrarSelectorPagina = (pagina) => {
                $(".page-item.numeracion[data-page='" + pagina + "']").show();
            };

            $(".page-item.numeracion").hide(); //ocultar todos los selectores de paginas 
            mostrarSelectorPagina(pagina);

            // seleccionar selectores, maximo 5 para que no haya desbordamiento
            let limInf = pagina - 2;
            let limSup = limInf + 4;
            if (limSup > props.grupos.length){limSup = props.grupos.length; limInf = limSup - 4;}
            if (limInf < 1){limInf = 1; limSup = limInf + 4;}

            for (limInf; limInf <= limSup; limInf++) {
                mostrarSelectorPagina(limInf);
            }


        }


    });
}





//https://datatables.net/plug-ins/i18n/Spanish_Colombia.html
//https://datatables.net/reference/option/language


export { DataTables };



