import React from 'react';


function scrollToSection(sectionId) {
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}



const actualizacion = 'Actualizado: 15/10/2023';



function QuienesSomos(props) {

  
  return (
    <>
    <section  className='caja3-block info-section'>

      <figure className='imagen'>
          <img src='/assets/img/Info/yo.jpg' alt="imagen de Fabio H. Mancipe" />
      </figure>

      <h2>Acerca de FANCIPE</h2>
      <h4>¿Quienes somos?</h4>

      <p>Fabio H. Mancipe, desarrollador, administrador y dueño del sitio web www.fancipe.com, es un lugar que oferta conocimiento de acceso libre y gratuito a todos sus visitantes; No hay temática específica en el sitio web, toda vez que su propósito es mantener contenido que apasiona a su administrador, la cual puede ser de tecnología, sistema de salud de Colombia, asuntos comunitarios, matemáticas básicas y avanzadas, etc....</p>

      <p>Actualmente, Realizo software y asistencia en TI bajo demanda para familiares, buenos amigos, colegas y demás interesados.</p>


      <h4>Misión</h4>
      <p>El propósito de mi sitio web es compartir mi pasión y conocimiento con el mundo. Aquí encontrarás contenido enriquecedor creado con amor y dedicación. Mi misión es inspirar, educar y entretener a mis lectores a través de artículos, y recursos relacionados con mis intereses y pasiones.</p>

      <h4>Visión</h4>
      <p>Mi visión para este sitio web es convertirlo en un destino de referencia para todos aquellos que comparten mis intereses y pasiones. Deseo que mi página sirva como un lugar en línea en donde las personas puedan conectarse, aprender y crecer juntas. Sueño con ver a mi sitio web crecer y evolucionar a medida que continúo explorando y compartiendo mis pasiones y conocimientos con el mundo.</p>
    </section>
    </>
  );
}



function Cookies(props) {

  
  return (
    <>
    <section className='caja3-block info-section'>
    <p>{actualizacion}</p>
  
    <h2>Cookies, términos, condiciones y políticas de uso del sitio web www.fancipe.com, en adelante FANCIPE</h2>

      <h4>Cookies</h4>
      <li><a onClick={() => scrollToSection("section1")}>¿Qué son las cookies?</a></li>
      <li><a onClick={() => scrollToSection("section2")}>Finalidades de las cookies</a></li>
      <li><a onClick={() => scrollToSection("section3")}>Aceptación de uso de Cookies</a></li>
      <li><a onClick={() => scrollToSection("section4")}>Configuración de Cookies</a></li>
      <h4>Términos y Condiciones de Uso del sitio web</h4>
      <li><a onClick={() => scrollToSection("section5")}>Condiciones de acceso</a></li>
      <li><a onClick={() => scrollToSection("section6")}>Condiciones de Uso del Contenido</a></li>
      <li><a onClick={() => scrollToSection("section7")}>Procedimiento de notificación de Contenido Violatorio</a></li>
      <li><a onClick={() => scrollToSection("section8")}>Frente a los límites de la Responsabilidad</a></li>
      <li><a onClick={() => scrollToSection("section9")}>Legislación aplicable</a></li>
      <li><a onClick={() => scrollToSection("section10")}>Modificación de los Términos Legales</a></li>


      <h2>Cookies</h2>
      <p>Este sitio web hace uso de cookies propias y de terceros. Tenga en cuenta que el uso de las cookies va a permitir optimizar su experiencia en este sitio web.</p>

      <h4 id='section1'>¿Qué son las cookies?</h4>
      <p>Una cookie es un fichero que se descarga en el computador/teléfono inteligente/tableta del usuario al acceder a determinadas páginas web.</p>

      <h4 id='section2'>Finalidades de las cookies</h4>
      <p>FANCIPE harán uso de las cookies para:<br/>
      *Determinar sus preferencias de navegación<br/>
      *para efectos promocionales, comerciales y publicitarios<br/>
      *Para efectos estadísticos, entre otros fines.</p>

      <h4 id='section3'>Aceptación de uso de Cookies</h4>
      <p>Al aceptar estos Términos y condiciones, Usted acepta que FANCIPE utilicen cookies para los fines aquí señalados. El uso continuo de esta página web se entenderá como aceptación de los Términos y Condiciones y como consecuencia, del uso de las cookies.</p>

      <h4 id='section4'>Configuración de Cookies</h4>
      <p>Usted podrá configurar su navegador para que notifique y rechace la instalación de las cookies enviadas por el Sitio web, sin que ello impida su acceso a los Contenidos. Sin embargo, tenga en cuenta que al desactivar el uso de cookies usted podrá experimentar una disminución en la calidad de funcionamiento de la página web.</p>



      <h2>Términos y Condiciones de Uso del sitio web</h2>
      <p>Estos términos y condiciones de uso del sitio web fancipe.com, regulan el uso que Usted puede dar como Visitante al contenido publicado en el Sitio web, así como la conducta que Usted puede desarrollar durante su visita y uso del Sitio web. Por el solo hecho de visitar el Sitio web, Usted acepta estos términos y condiciones y consiente en someterse a los mismos. Usted asume la responsabilidad por el uso que haga del Sitio web. Es su responsabilidad revisar y cumplir estos Términos y Condiciones de Uso periódicamente.</p>

      <h4 id='section5'>Condiciones de acceso</h4>
      <p>El acceso al Sitio web por parte de los Visitantes es libre y gratuito; los contenidos están dirigidos para personas mayores de edad. En caso de ser Usted menor de edad, debe obtener con anterioridad el acompañamiento y consentimiento de sus padres, tutores o representantes legales, quienes serán responsables de los actos que Usted lleve a cabo en contravención a estos términos y condiciones de uso del Sitio web. Se da por entendido que los menores de edad que accedan y usen el Sitio web cuentan con este consentimiento. El acceso al Sitio web permite acceder a toda la información publicada por FANCIPE, por sus desarrolladores, programadores, editores de contenido y demás colaboradores.</p>


      <h4 id='section6'>Condiciones de Uso del Contenido</h4>
      <p>Existe Contenido (que incluye o puede incluir textos, información, imágenes, fotografías, dibujos, logos, diseños, video, multimedia, software, aplicaciones, música, sonidos, entre otros, así como su selección y disposición), es propiedad de los anunciantes, de terceros con los derechos reservados, o de FANCIPE. Como tal, dicho Contenido se encuentra protegido por las leyes y tratados nacionales e internacionales vigentes en materia de Propiedad Intelectual. FANCIPE confiere a Usted una licencia para visualizar y/o interactuar con las aplicaciones y demás herramientas del contenido en el Sitio web, y para realizar una copia caché en su computador con dicho fin únicamente. Este documento puede ser impreso y almacenado por Usted.
      <br/><br/>
      Aparte de lo anterior, FANCIPE no confiere a los Visitantes ninguna licencia para descargar, reproducir, copiar, enmarcar, compilar, cargar o republicar en ningún sitio de Internet, Intranet o Extranet, adaptar, modificar, transmitir, vender ni comunicar al público, total o parcialmente, el Contenido; Cualquiera de estas actividades requiere de la autorización previa, expresa y por escrito de FANCIPE o de los anunciantes/terceros que cuente con los derechos reservados del contenido, so pena de incurrir en violación a los derechos de propiedad industrial e intelectual, y someterse a las consecuencias civiles y penales de tal hecho, así como al derecho de FANCIPE de revocar la licencia aquí conferida; Se exceptúa de la presente las aplicaciones desarrolladas y programadas directamente por FANCIPE, en la que su propósito está destinado a generar un producto descargable por parte del visitante.
      <br/><br/>
      Salvo que se indique expresamente lo contrario en la presente, nada de lo dispuesto en los presentes Términos y Condiciones de Uso del Sitio web deberá interpretarse en el sentido de otorgar una licencia sobre derechos de propiedad intelectual, ya sea por impedimento legal, implícitamente o de cualquier otra forma. Esta licencia podrá ser revocada en cualquier momento y sin preaviso, con o sin causa.
      <br/><br/>
      Usted se compromete a hacer un uso adecuado del Contenido; Usted se compromete a no:
      <br/><br/>
      Utilizar el Contenido para incurrir y/o incitar a terceros a incurrir en actividades ilícitas, ilegales o contrarias a la buena fe y al orden público, o para difundir contenidos o propaganda de carácter racista, xenófobo, pornográfico-ilegal, de apología del terrorismo o atentatorio contra los derechos humanos.
      <br/><br/>
      Usar secuencias de comandos automatizados para recopilar información publicada en el Sitio web o a través del Sitio web o para interactuar de cualquier otro modo con los mismos.
      <br/><br/>
      Provocar daños en los sistemas físicos y lógicos de FANCIPE, de sus proveedores o de terceras personas, introducir o difundir en la red virus informáticos, troyanos, código malicioso o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar daños en y/o estén diseñados para interrumpir, destruir o limitar la funcionalidad de cualquier software, hardware o equipo de telecomunicaciones o para dañar, deshabilitar, sobrecargar o perjudicar el Sitio web de cualquier modo.
      <br/><br/>
      Intentar acceder, recolectar o almacenar los datos personales de otros Visitantes y/o Usuarios del Sitio web y, en su caso, utilizar las cuentas de correo electrónico de otros Visitantes y/o Usuarios y modificar o manipular sus mensajes.</p>



      <h4 id='section7'>Procedimiento de notificación de Contenido Violatorio</h4>
      <p>FANCIPE respeta y promueve la protección de los derechos de propiedad intelectual de terceros; En caso que Usted encuentre en este Sitio web contenido que usted pueda considerar violatorio de sus derechos, le solicitamos por favor enviar una comunicación escrita a nuestro correo electrónico info@fancipe.com
      <br/><br/>
      Por favor, incluya en la comunicación la siguiente información:
      <br/><br/>
      La página o URL en la que usted considera violatorio.
      <br/><br/>
      Una descripción clara y detallada del contenido considerado violatorio.
      <br/><br/>
      Una explicación de en qué manera el contenido en mención atenta contra sus derechos. En caso de contar con documentos que demuestren la titularidad de sus derechos, le rogamos anexar una copia.
      <br/><br/>
      Una declaración bajo la gravedad del juramento, de que la información enviada en su comunicación es correcta.
      <br/><br/>
      Sus datos de contacto, tales como nombre, identificación, dirección de correspondencia escrita y electrónica, teléfono, celular, etc.
      <br/><br/>
      Por favor tenga en cuenta que en caso que la información enviada en su comunicación sea incorrecta, FANCIPE no asume responsabilidad por las consecuencias de su retiro. En consecuencia, Usted debe ser consciente de que al enviar su comunicación asume los daños y perjuicios que pueda ocasionar a terceros de buena fe.
      <br/><br/>
      FANCIPE revisará el caso, y si encuentra mérito en su queja, procederá a retirar el contenido. 
      <br/><br/>
      En caso que la información haya sido publicada por un anunciante/tercero, es responsabilidad del anunciante/tercero respectivo. Cualquier reclamación por infracción a la propiedad industrial y de derechos de autor deberá ser dirigida directamente al Anunciante. Cualquier promoción, incluida la entrega y el pago por bienes y servicios, y cualquier otro término, condición, garantía o representación asociados con dichos tratos o promociones, corresponden a una relación exclusiva entre el anunciante y el Usuario, sin participación de FANCIPE.</p>



      <h4 id='section8'>Frente a los límites de la Responsabilidad</h4>
      <p>FANCIPE no es responsable por:
      <br/><br/>
      Las caídas del Sitio web y la falla en el suministro del servicio, quedando exonerada por cualquier tipo de daños y perjuicios causados debido a la no disponibilidad y/o interrupción del servicio ocasionados por fallas o no disponibilidad de las redes y servicios de telecomunicaciones utilizados para soportar el Sitio web, y que sean ajenos a su voluntad.
      <br/><br/>
      Los daños y perjuicios causados por virus informáticos, troyanos, código malicioso o cualesquiera otros sistemas físicos o lógicos a los sistemas de los Usuarios.
      <br/><br/>
      Errores mecanográficos y/o tipográficos que aparezcan en el Contenido.
      <br/><br/>
      El contenido publicitario que aparezca en el Sitio web, el cual es responsabilidad del anunciante/tercero respectivo. Cualquier reclamación por infracción a la propiedad industrial y de derechos de autor deberá ser dirigida directamente al Anunciante. Cualquier promoción, incluida la entrega y el pago por bienes y servicios, y cualquier otro término, condición, garantía o representación asociados con dichos tratos o promociones, corresponden a una relación exclusiva entre el anunciante y el Usuario, sin participación de FANCIPE.
      <br/><br/>
      Las opiniones publicadas por los Usuarios a través de redes sociales, foros de opinión, blogs y otros servicios que ofrezca FANCIPE al público.
      <br/><br/>
      El contenido de los sitios vinculados mediante hipervínculos que aparezcan en el Sitio web, incluyendo sin limitación, cualquier vínculo contenido en los Sitios Vinculados, cualquier cambio o actualización a los Sitios Vinculados, cualquier tipo de transmisión recibida o enviada desde o hacia Sitios Vinculados, o el funcionamiento incorrecto de los Sitios Vinculados. FANCIPE proporciona estos Sitios Vinculados sólo por comodidad, y la inclusión de cualquiera de ellos no implica aprobación por parte de FANCIPE a ninguno de estos sitios ni ninguna asociación con sus operadores.</p>



      <h4 id='section9'>Legislación aplicable</h4>
      <p>Los Términos y condiciones legales se regirán e interpretarán de acuerdo con las leyes de la República de Colombia.</p>

      <h4 id='section10'>Modificación de los Términos Legales</h4>
      <p>FANCIPE podrá modificar estos Términos y Condiciones de Uso del Sitio web en cualquier momento y sin previo aviso.</p>
      </section>
    </>
  );
}




function HabeasData(props) {

  
  return (
    <>
    <section className='caja3-block info-section'>
    <p>{actualizacion}</p>

    <h2>Política de privacidad y habeas data aplicable para Colombia del sitio web www.fancipe.com, en adelante FANCIPE</h2>

    <h4>POLÍTICA DE PRIVACIDAD</h4>

    <p>La presente Política de Privacidad establece los términos en que FANCIPE protege la información del usuario al momento de utilizar el sitio web. Su propietario está comprometido con la seguridad de los datos de sus usuarios.</p>


    <h4>Información que es recogida</h4>
    <p>Nuestro sitio web NO recoge información personal; para usar el sitio web, FANCIPE NO solicita registro alguno.</p>


    <h4>Cookies</h4>
    <p>Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso para almacenarse en su computador, al aceptar dicho fichero se crea y la cookie sirve entonces para tener información respecto al tráfico web, y también facilita las futuras visitas a una web recurrente. Otra función que tienen las cookies es que con ellas las web pueden reconocerte individualmente y por tanto brindarte el mejor servicio personalizado.</p>


    <p>Nuestro sitio web emplea las cookies para poder identificar las páginas que son visitadas y su frecuencia. Esta información es empleada únicamente para análisis estadístico y después la información se elimina de forma permanente. Usted puede eliminar las cookies en cualquier momento desde su computador. Sin embargo las cookies ayudan a proporcionar un mejor servicio de los sitios web, estás no dan acceso a información de su computador ni de usted, a menos de que usted así lo quiera y la proporcione directamente noticias. Usted puede aceptar o negar el uso de cookies, sin embargo la mayoría de navegadores aceptan cookies automáticamente pues sirve para tener un mejor servicio web. También usted puede cambiar la configuración de su computador para declinar las cookies. Si se declinan es posible que no pueda utilizar algunos de nuestros servicios.</p>


    <h4>Enlaces a Terceros</h4>
    <p>Este sitio web pudiera contener enlaces a otros sitios que pudieran ser de su interés. Una vez que usted de clic en estos enlaces y abandone nuestra página, ya no tenemos control sobre al sitio al que es redirigido y por lo tanto no somos responsables de los términos o privacidad ni de la protección de sus datos en esos otros sitios terceros. Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted está de acuerdo con estas.</p>

    <p>FANCIPE no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento (en caso de que exsista), salvo que sea requerido por un juez con un orden judicial.</p>

    <p>FANCIPE Se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.</p>


    <h4>HABEAS DATA en la República de Colombia - Ley 1581 de 2012</h4>

    <h4>Generalidades</h4>
    <p>La presente política se define de conformidad con la Ley 1581 de 2012 de la República de Colombia, la cual tiene por objeto dictar las disposiciones generales para la protección de datos personales y desarrollar el derecho constitucional que tienen todas las personas a conocer, actualizar y rectificar las informaciones que se hayan recogido sobre ellas, en bases de datos o archivos así como el derecho a la información; FANCIPE se permite informar a los visitantes de su sitio web que se ha realizado el presente documento, con el ánimo de dar cumplimiento a la citada norma referente a los datos de carácter personal que recoja y maneje FANCIPE, en caso de que exista.</p>

    <h4>Marco legal aplicable</h4>
    <p>Ley 1581 de 2012: Por la cual se dictan disposiciones generales para la protección de datos personales.</p>

    <h4>Objetivo</h4>
    <p>La ley 1581 de 2012 tiene por objeto desarrollar el derecho constitucional que tienen todas las personas a conocer, actualizar y rectificar las informaciones que se hayan recogido sobre ellas en bases de datos o archivos, y los demás derechos, libertades y garantías constitucionales a que se refiere el artículo 15 de la Constitución Política de la República de Colombia, así como el derecho a la información consagrado en el artículo 20 de la misma.</p>
    

    <h4>Casos en la cual no se aplica la ley de proteccion de datos personales</h4>

    <p>A las bases de datos o archivos mantenidos en un ámbito exclusivamente personal o doméstico.</p>
    <p>A las bases de datos y archivos que tengan por finalidad la seguridad y defensa nacional, así como la prevención, detección, monitoreo y control del lavado de activos y el financiamiento del terrorismo.</p>
    <p>A las Bases de datos que tengan como fin y contengan información de inteligencia y contrainteligencia.</p>
    <p>A las bases de datos y archivos de información periodística y otros contenidos editoriales.</p>
    <p>A las bases de datos y archivos regulados por la Ley 1266 de 2008.</p>
    <p>A las bases de datos y archivos regulados por la Ley 79 de 1993.</p>


    <h4>Finalidades en la captura, uso y tratamiento de datos personales</h4>
    <p>FANCIPE NO recoge información personal; para usar el sitio web, FANCIPE NO solicita datos personales y/o registro alguno.</p>


    <h4>Protección datos personales de menores de edad y adolescentes</h4>
    <p>En atención a lo dispuesto en la Ley 1581 de 2012 y el Decreto 1377 de 2013, FANCIPE asegura que NO se realiza tratamiento de datos personales de menores de edad.</p>

    <h4>Transferencia y transmisión internacional de datos personales</h4>
    <p>En atención a lo dispuesto en la Ley 1581 de 2012 y el Decreto 1074 de 2015, FANCIPE asegura que NO se realiza transmisión internacional de datos personales con ninguna empresa u organización.</p>


    <h4>Registro nacional de bases de datos</h4>
    <p>Según decreto 90 de 2018 en su artículo 1, FANCIPE NO es objeto de inscripción en el registro nacional de bases de datos al NO reunir las características enunciadas en el citado decreto; de todos modos, FANCIPE NO solicita ni realiza registros de datos personales en bases de datos.</p>


    <h4>Encargado y responsable del tratamiento</h4>
    <p>FANCIPE y/o Fabio H. Mancipe</p>
    <p>info@fancipe.com</p>
    <p>Si usted considera que existe contenido violatorio de la ley habeas data, le solicitamos por favor enviar una comunicación escrita a nuestro correo electrónico; Por favor, incluya en la comunicación la siguiente información:</p>
    <p>La página o URL en la que usted considera violatorio.</p>
    <p>Una descripción clara y detallada del contenido considerado violatorio de la ley habeas data.</p>
    <p>La solicitud será atendida por su dueño.</p>
    </section>
    </>
  );
}




export { QuienesSomos, Cookies, HabeasData };
